.loader {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.spaceName {
	margin-top: 20px;
	margin-bottom: 30px;
}

.extraInfo {
	display: grid;
	grid-template-columns: auto auto auto 1fr;
	margin-bottom: 45px;
	gap: 30px;
	border-bottom: 2px solid var(--main-dark);
	border-top: 2px solid var(--main-dark);
	padding: 15px 0;
	margin-top: 30px;
}

.extraInfo span {
	display: grid;
	grid-template-columns: auto 1fr;
	font-weight: 500;
	font-size: 22px;
	line-height: 31px;
	gap: 10px;
	align-items: center;
}



.task_desc h4 {
	display: flex;
	align-items: center;
	gap: 10px	;
}

.descField {
	background-color: transparent;
	width: 100%;
	border: 0;
	height: 100%;
	font-weight: 400;
	font-size: 19px;
	font-family: var(--primary-font);
	line-height: 1.5em;
	resize: none;
}

.descField:focus {
	background-color: var(--white);
	outline: none;
	padding: 10px;
}

.task_desc_content {
	margin-top: 30px;
	line-height: 31px;
}

.commentsWrap {
	position: relative;
	padding-top: 20px;
	margin-top: 30px;
}

.commentsWrap h4 {
	display: grid;
	grid-template-columns: auto auto;
	justify-content: start;
	gap: 5px;
	margin-bottom: 20px;
}

.addComment {
	height: 60px;
	padding: 0!important;
}

.addComment textarea {
	height: 40px!important;
	padding: 18px 100px 0 15px!important;
}

.addComment div {
	padding: 0!important;
}

.addComment label {
	font-size: 19px!important;
	font-family: var(--primary-font)!important;
	margin: 0!important;
	padding: 3px 10px!important;
	font-style: normal!important;
	font-weight: 500!important;
	background-color: var(--dashboard-bg)!important;
}

.addBtn {
	position: absolute;
	bottom: 14px;
	right: 11px;
	border-radius: 50% ;
	width: 30px;
	height: 30px;
	padding: 0;
	border: 0;
	background-color: #0999B9;
}

.addBtn:hover {
	background-color: #0E7B94;
}

.addBtn  svg {
	margin-top: 2px;
	fill: var(--white);
}

.comment_item {
	margin-bottom: 15px;
}

.commentDate {
	color: #89A2B0;
	font-weight: 400;
	font-size: 15px;
	line-height: 17px;
}

.commentText {
	font-weight: 400;
	font-size: 19px;
	line-height: 22px;
	color: var(--main-dark);
	word-break: break-word;
}

.commentBtns  {
	color: #89A2B0;
	font-weight: 400;
	font-size: 15px;
	line-height: 17px;
	margin-top: 15px;
	display: flex;
	gap: 10px;
}

.commentBtns span {
	color: #89A2B0;
	font-weight: 400;
	font-size: 15px;
	line-height: 17px;
	position: relative;
	overflow: hidden;
	display: inline-block;
	cursor: pointer;
}

.commentBtns span:hover::after {left:-100%;}

.backBtn {
	display: inline-block;
	margin-top: 30px;
}

.notFound {
	max-width: 800px;
	margin: 0 auto;
	font-size: 50px;
	text-align: center;
}

.componentNotFound {
	height: 75svh;
}


@media screen and (max-width: 1100px) {
	.extraInfo {
		grid-template-columns: auto 1fr;

	}
}
@media screen and (max-width: 768px) {
	.extraInfo {
		grid-template-columns: 1fr;

	}
	
	.extraInfo span {
		font-size: 18px;
		line-height: 17px;
	}
}