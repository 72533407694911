.contentWrap h1, 
.contentWrap h1 span {
	font-family: var(--secondary-font);
	font-weight: 400!important;;
	font-style: normal;
	margin-bottom: 20px;
	font-size: 48px;
}

.contentWrap h2, .contentWrap h2 span,
.contentWrap h2 strong  {
	font-family: var(--primary-font)!important;
	font-weight: 500!important;;
	font-style: normal;
	margin-bottom: 20px;
	font-size: 35px!important;
	background-color: transparent!important;
	line-height: 40px;
}

.contentWrap h3, .contentWrap h3 span {
	font-weight: 500!important;
	font-family: var(--primary-font)!important;
	font-size: 25px!important;
	color: var(--main-dark)!important;
	background: transparent!important;
	line-height: 32px!important;
}


.contentWrap h4, .contentWrap h4 span {
	font-weight: 500!important;
	font-family: var(--primary-font)!important;
	font-size: 19px!important;
	color: var(--main-dark)!important;
	background: transparent!important;
	line-height: 24px!important;
}

.contentWrap h3 {
	margin-top: 15px;
	margin-bottom: 10px;
}

.contentWrap strong {
	font-weight: 500;
}

.imageContainer {
	overflow: hidden;
	max-width: 100%;
	max-height: 600px;
	margin-bottom: 20px; 
}

.imageContainer  img {
	max-width: 100%;
	width: 100%;
}

.contentWrap h4, .contentWrap h4 span {
	font-weight: 500!important;
}

.contentWrap p, 
.contentWrap p span,
.contentWrap li,
.contentWrap div {
	font-family: var(--primary-font)!important;
	color: var(--main-dark)!important;
	line-height: 25px!important;
	background:transparent!important;
	letter-spacing: -0.01em;
	font-size: 18px!important;
}

.contentWrap p {
	margin-bottom: 10px;
}

.contentWrap p strong,
.contentWrap p span strong {
	font-weight: 500;
}

.contentWrap a,
.contentWrap p a,
.contentWrap  a span{
	font-size: 18px!important;
	font-weight: 400!important;
	color: var(--middle-blue)!important;
	text-decoration: underline;
	font-family: var(--primary-font)!important;
} 

.contentWrap a:hover,
.contentWrap p a:hover,
.contentWrap  a:hover span  {
	text-decoration: none;
}

.contentWrap ul {
	margin: 16px 0 24px;
}

.contentWrap li * {
	grid-column: 2 / 3;
}

.contentWrap li::before {
	grid-column: 1 / 2;
}

.contentWrap ol {
	list-style:decimal;
	margin-left: 24px;
}

.contentWrap ol li {
	margin-bottom: 20px;
}

.contentWrap ol li::marker {
	font-weight: 400!important;
	font-size: 18px!important;
	line-height: 25px!important;
	background-color: transparent!important;
}

.contentWrap ul {
	list-style: disc;
}

.contentWrap ul li {
	margin-bottom: 10px;
	font-family: var(--primary-font)!important;
	font-size: 18px!important;
	font-weight: 400;
	font-style: normal;
	line-height: 25px;
	margin-left: 48px;
	color: var(--main-dark)!important;
	position: relative;
	gap: 10px;
}

.contentWrap li span {
	font-weight: 400!important;
	font-size: 18px!important;
	font-family: var(--primary-font)!important;
	line-height: 22px!important;
	background-color: transparent!important;
}

.contentWrap ul li b {
	font-weight: 500;
}

.contentWrap  table {
	border: 1px solid var(--main-dark);
	border-spacing: 0;
	border-right: 0;
	border-bottom: 0;
	margin-bottom: 24px;
	margin-top: 24px;
	width: 100%;
}

.contentWrap  table td {
	border-bottom:  1px solid var(--main-dark);
	padding: 10px;
	border-right: 1px solid var(--main-dark);
}

.contentWrap  table td p {
	margin-bottom: 0;
}

.contentWrap  table thead th {
	background-color: var(--first-logo-color);
	border-bottom: 1px solid var(--main-dark);
	border-right: 1px solid var(--main-dark);
	padding: 6px 10px;
}

.contentWrap  table thead th p,
.contentWrap  table thead th p span {
	margin-bottom: 0;
	vertical-align: middle;
	font-weight: 500;
}

.meta {
	display: flex;
	justify-content: space-between;
	border-bottom: 2px solid var(--main-dark);
	border-top: 2px solid var(--main-dark);
	padding: 15px 0;
	margin-top: 24px;
	gap: 20px;
	margin-bottom: 40px;
}

.saveMark,
.templatesMark {
	justify-self: end;
	display: flex;
	align-items: center;
	gap: 4px;
	cursor: pointer;
	font-weight: 500;
	font-size: 24px;
	line-height: 25px;
	color: var(--light-grey);
	transition: all 0.4s ease;
}

.typeMark {
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 32px;
	border: 2px solid var(--main-dark);
	border-radius: 48px;
	padding: 12px 20px;
}

.JournalIcon {
	margin-left: 5px;
}

.templatesMark  {
	color: var(--main-dark);
	cursor: default;
}

.templatesMark  path {
	stroke: var(--main-dark)
}

.saveMark {
	cursor: pointer;
}

.saveMark:hover {
	color: var(--main-dark);
}

.backBtn {
	cursor: pointer;
}

.reding_time {
	font-weight: 500;
	font-size: 24px;
	line-height: 28px;
	display: flex;
	align-items: center;
	gap: 8px;
} 

.reding_time svg {
	width: 32px;
	height: 32px;
}

.contentWrap blockquote {
	border-left: 5px solid var(--third-logo-color);
	margin: 40px 0 32px;
	padding-left: 20px;
	font-weight: 500;
	font-size: 18px;
	line-height: 25px;
}

.contentWrap blockquote h2,
.contentWrap blockquote h3, 
.contentWrap blockquote h4 {
	color: var(--third-logo-color)!important;
	font-weight: 500;
	font-size: 20px;
	line-height: 26px;
}

.contentWrap blockquote div span {
	font-weight: 500!important;
	font-size: 18px!important;
	line-height: 26px;
	font-family: var(--primary-font)!important;
}

.contentWrap blockquote h2 span,
.contentWrap blockquote h3 span, 
.contentWrap blockquote h4 span {
	color: var(--third-logo-color)!important;
	font-weight: 500;
	font-size: 20px!important;
	line-height: 26px;
}

.templates {
	border-top: 2px solid var(--main-dark);
	border-bottom: 2px solid var(--main-dark);
	margin-bottom: 40px;
	padding: 40px 0;
	margin-top: 10px;
}

.templates h3 {
	font-family: var(--primary-font);
	font-size: 26px;
	font-weight: 500;
	font-style: normal;
	line-height: 32px;
	margin-bottom: 24px;
	display: flex;
	gap: 10px;
}

.templates  h3 path {
	stroke: var(--main-dark);
}

.templateItem {
	border: 2px solid var(--main-dark);
	padding: 16px;
	font-size: 18px;
	border-radius: 15px;
	display: flex;
	font-weight: 500;
	line-height: 26px;
	letter-spacing: -0.22px;
	margin-bottom: 10px;
	align-items: center;
	gap: 10px;
	cursor: pointer;
	transition: all 0.4s ease;
	background-color: var(--third-logo-color);
}

.templateItem:hover {
	background-color: var(--green-500);
}

.backBtn {
	margin-top: 30px;
	display: inline-block;
}

.dometic_msg {
	background-color: var(--modal-gray);
	border-radius: 20px;
}

.templates_wrap {
	display: grid;
	gap: 15px;
}

@media screen and (max-width: 935px) {
	.templatesMark ,
	.reding_time ,
	.typeMark {
		font-size: 22px;
	}

	.typeMark {
		padding: 0 20px;
	}
}
@media screen and (max-width: 768px) { 
	.title {
		font-size: 40px;
	}

	.saveMark, .templatesMark {
		justify-self: start;
	}

	.contentWrap ol {
		margin-top: 15px;
	}

	.contentWrap ol li {
		margin-bottom: 10px;
	}

	.meta {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		margin-bottom: 20px;
	}

	.reding_time,
	.templatesMark,
	.typeMark {
		font-size: 16px;
	}

	.typeMark {
		padding: 0;
		border: 0;
	}

	.templateItem { 
		padding: 10px;
	}

	.contentWrap ul li {
		margin-left: 0;
	}

	.templates {
		margin-bottom: 20px;
	}

	.contentWrap ul li,
	.contentWrap ol li,
	.contentWrap li span,
	.contentWrap p span,
	.contentWrap p,
	.contentWrap p a,
	.templateItem  {
		font-size: 18px!important;
		line-height: 25px!important;
	}

	.contentWrap ul li::before {
		margin-top: 1px;
	}

	.contentWrap ul li {
		margin-left: 20px;
	}

	.contentWrap h1, 
	.contentWrap h1 span {
		list-style-type: 50px;
		font-size: 32px!important;
	}

	.contentWrap h2, .contentWrap h2 span,
	.contentWrap h2 strong  {
		font-size: 25px!important;
		line-height: 30px;
	}

	.contentWrap h3, .contentWrap h3 span {
		font-size: 22px!important;
		line-height: 27px!important;
	}


	.contentWrap h4, .contentWrap h4 span {
		font-weight: 500!important;
		line-height: 25px!important;
	}

	.contentWrap p, 
	.contentWrap a,
	.contentWrap p a,
	.contentWrap a span,
	.contentWrap p span,
	.contentWrap ol li::marker ,
	.contentWrap ul li,
	.contentWrap li span,
	.contentWrap  table thead th,
	.contentWrap  table td,
	.contentWrap  table td p,
	.contentWrap  table thead th p span,
	.contentWrap li {
		font-size: 18px!important;
		line-height: 24px!important;
	}

}