.resultBlock {
	margin-bottom: 10px;
	border: 2px solid var(--main-dark);
	border-radius: 10px;
	padding: 15px 20px;
	background-color: var(--modal-gray);
	display: grid;
	grid-template-columns: 1fr auto auto;
	gap: 20px;
	align-items: center;
}

.resultBlockLink:hover .resultBlock {
	background-color: var(--light-blue) ;
}

.icon {
	margin-right: 10px;
	width: 21px;
}


.title a {
	display: flex;
	align-items: center;
	transition: all 0.2s ease;
	font-size: 22px;
	line-height: 27px;
}

.title a:hover {
	color: var(--middle-blue)
} 

.title a:hover  svg{
	fill: var(--middle-blue)
} 

.typeMark {
	border: 1px solid var(--main-dark);
	border-radius: 15px;
	font-size: 14px;
	padding: 6px 10px;
	width: 100px;
	text-align: center;
}

.reading_time {
	display: flex;
	gap: 5px;
	align-items: center;
}

@media screen and (max-width: 768px) {
	.searchWrap .resultBlock {
		padding: 15px;
		grid-template-columns: auto 1fr;
		
	}

	.searchWrap .resultBlock h4 {
		grid-column-start: 1;
		grid-column-end: -1;
		line-height: 25px;
	}
}