
.dashboard_img {
	max-width: 100px;
}

.dashboardHeader{
	margin-bottom: 20px;
}

.dashboardHeader img{
	width: 126px;
	margin-bottom: 15px;
}

.loadingContent {
	display: grid;
	justify-items: center;
	grid-template-columns: 1fr;
}


.wyswyg_editorWrap strong {
	font-weight: 600;
}


