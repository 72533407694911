.circleStep {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	font-family: var(--primary-font);
	font-weight: 500;

}

.circleStep.big {
	width: 80px;
	height: 80px;
	font-size: 32px;
	line-height: 53px;

}

.circleStep.middle {
	width: 40px;
	height: 40px;
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
}


.circleStep.small {
	width: 40px;
	height: 40px;
	font-size: 24px;
	font-style: normal;
	font-weight: 500;

}

@media screen and (max-width: 1024px) {
	.circleStep.big {
		width: 70px;
		height: 70px;
		font-size: 30px;
		line-height: 66px;
	}

	.circleStep.small {
		width: 30px;
		height: 30px;
		font-size: 20px;
		line-height: 30px;
	}

	.circleStep.middle {
		width: 35px;
		height: 35px;
		font-size: 25px;
		font-style: normal;
		font-weight: 500;
	}
} 