.searchForm {
	position: relative;
}

.searchForm .searchField input {
	border: 0;
	border-radius: 10px;
	padding: 10px;
	font-size: 16px;
	color: var(--main-dark);
	padding-left: 45px;
	font-weight: 500;
}

.searchForm .searchField input::placeholder {
	color: var(--main-dark);
	opacity: 1;
	font-weight: 500;
}

.searchIcon {
	position: absolute;
	left: 13px;
	top: 13px;	
}

.modalSearch {
	position: fixed;
	background-color: var(--modal-gray);
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 700;
	padding: 20px;
}

@media screen and (max-width: 768px) {
	.searchField {
		transition: all 0.4s ease-in-out;
	}

	.hideInput .searchField {
		opacity: 0;
		width: 0!important;

	}

	.showInput .searchField{
		opacity: 1;
		width: 150px!important;
	}

	.hideInput .searchIcon {
		position: relative;
		left: 0;
		top: 12px;
		right: 10px;
	}
}