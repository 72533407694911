
.firstSlideIcon {
	height: 50px;
	margin-bottom: 20px;
}

.stepperPosition {
	top: auto!important;
	bottom: 0;
}

.firstSlide {
	max-width: 900px!important;
	margin-left: 30px;

}

.firstSlide p {
	font-size: 19px;
	line-height: 31px;
	margin-bottom: 15px;
}

.firstSlide a {
	color: var(--white);
	font-size: 25px;
	line-height: 30px;
	font-weight: 400;
}

.firstSlide a:hover::after {
	background-color: var(--white);
} 

.groupButton {
	margin-top: 30px;
	display: flex;
	gap: 30px	;
}

.groupButton button {
	min-width: 300px;
}

.goBack {
	background-color: #E1E2E2;
}

button.dark:hover {
	border-color: var(--white);
	color: var(--white);
}

.firstSlide .desc  {
	color: var(--white);
}

.firstSlide .desc h5{
	color: var(--white);
}

.firstSlide .desc svg circle,
.firstSlide .desc svg path{
	stroke: var(--white);
}

.firstSlide .desc p {
	font-size: 19px;
}

.form {
	max-width: 980px;
	padding-left: 10px;
}

.form input {
	max-width: 400px;;
}

.form h3 {
	font-size: 25px;
	line-height: 32px;
}

.error {
	color: var(--red)!important;
}

.stepMark {
	display: block;
	width: 53.64px;
	height: 53px; 
	text-align: center;
	color: var(--white);
	border-radius: 50%;
	background: var(--main-dark);
	font-size: 30px;
	font-weight: 500;
	font-style: normal;
	line-height: 53px;
	margin-bottom: 30px;
}

.form_fullField {
	margin-bottom: 20px;
}

.desc {
	border: 0;
	margin-top: 20px;
}

.desc div {
	border: 0!important;
	padding-left: 0!important;
	justify-content: start;
	gap: 10px;
	padding-top: 0;
}

.desc  h5 {
	font-weight: 500;
	font-size: 19px!important;
	line-height: 25px;
}

.checkbox  svg {
	display: none;
}

.radioboxes label svg {
	display: none;
}

.desc div svg {
	width: 20px;
	margin-top: -4px;
}

.desc div svg circle,
.desc div svg path {
	stroke-width: 3px;
}

.desc p{
	font-weight: 400;
	font-size: 17px;
	line-height: 25px;
	margin-bottom: 0;
}

.radioboxes {
	display: grid!important;
	margin-left: 10px;
}

.radioboxes label span{
	font-weight: 400;
	font-size: 18px;
	line-height: 25px;
	font-family: var(--primary-font);
	padding-left: 10px;
}

.firstSlide .checkbox input[type="checkbox"] ~ span {
	border: 2px solid var(--white)

}

.firstSlide .checkbox  input[type="checkbox"]:checked ~ span::before {
	filter: grayscale(100%) sepia(100%) brightness(200%) saturate(0%) contrast(1000%);

}

.disabled {
	opacity: 0.5;
}

.flexLine {
	display: flex;
	align-items: center;
}

@media screen and (max-width: 800px) {
	.groupButton button {
		min-width: 0;
		width: 100%;
	}
}

@media screen and (max-width: 480px) {
	.stepMark  {
		width: 35px;
		height: 35px;
		line-height: 35px;
		font-size: 22px;
	}

	.form  h3 {
		font-size: 20px;
	}

	.radioboxes label span{ 
		font-size: 16px;
	}

	.desc p {
		font-size: 16px;
	}
}
