.accordionSection {
	display: flex;
	flex-direction: column;
	margin-top: 36px;
}

.accordionItemContainer {
	display: block;
}

.accordionTitle {
	display: flex;
	justify-content: space-between;
	padding: 24px;
	cursor: pointer;
	border-bottom: 1px solid var(--main-dark);
}

.accordionSection h5 {
	font-size: 20px;
	font-weight: 500;
}

.accordionTitle.active {
	border-bottom: 0;
}

.accordionTitle svg {
	min-width: 30px;
	height: 30px;
	margin-left: 10px;
}

.accordionTitle.active  svg {
	transform: rotate(90deg);
}

.accordionSection div:first-child .accordionTitle {
	border-top: 1px solid var(--main-dark);
}

.dropdown {
	overflow: hidden;
	height: 0;
	padding: 0 24px;
	transition: all 0.2s ease;
	opacity: 0;
	border-bottom: 1px solid var(--main-dark);
	font-size: 15px;
	font-weight: 400;
	line-height: 25px;
}

.dropdown.show{
	overflow: visible;
	height: auto;
	padding: 0 20px 20px;
	opacity: 1;
}

.dropdown  p{
	margin-bottom: 15px;
}

.dropdown p a {
	font-size: 18px;
	font-weight: 400;
}

@media screen and (max-width: 840px) {
	.accordionTitle {
		align-items: center;
		padding: 20px 0;
	}
}

@media screen and (max-width: 768px) {
	.accordionSection h5 {
		font-size: 20px;
	}

	.accordionTitle {
		padding: 10px 0;
	}
}