.featureItem {
	display: grid;
	direction: ltr;
	grid-template-columns: 30% 60%;
	gap:   10%;
	margin-bottom: 160px;
	align-items: center;
}

.featureItem:nth-child(2n) {
	direction: rtl;
}

.image {	
	height: 400px;
}

.image img {
	max-width: 100%;
}

.optionsContent {
	text-align: left;
	
}

.optionsContent h4 {
	margin-bottom: 25px;
}

.optionsContent  li {
	background:  no-repeat 0 3px;
	font-weight: 400;
	font-size: 19px;
	line-height: 31px;
	margin-bottom: 10px;
	direction: ltr;
	margin-left: 34px;
}

.optionsContent  li svg {
	margin-right: 10px;
	vertical-align: middle;
	margin-left: -34px;
}

.quoteBlock {
	background-color: var(--second-logo-color);
	color: var(--main-dark);
	text-align: center;

}

.quoteBlock .section {
	padding-top: 129px;
	padding-bottom: 129px;
	max-width: calc( var(--max-container-width ) - 200px);
}

.quoteBlock p {
	margin-top: 25px;
	font-weight: 400;
	font-size: 25px;
	line-height: 32px;
}
@media screen and (max-width: 960px) {
	.featureItem {
		grid-template-columns: 1fr;
		direction: ltr;
		margin-bottom: 40px;
		gap: 40px;
	}

	.quoteBlock .section {
		padding-top: 40px;
		padding-bottom: 40px;
		max-width: 95;
	}
}

@media screen and (max-width: 768px) {
	.image {
		height: 200px;
		text-align: center;
	}

	.image svg,
	.image img  {
		height: 200px;
		width: auto;
	}
}