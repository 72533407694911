.chatbot_input_form {
	background-color: var(--second-logo-color);
	box-shadow: 0 0 0 100vmax var(--second-logo-color);
	clip-path: inset(0 -100vmax);
	padding: 20px 0;
	margin-top: 20px;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}



.formField {
	display: flex;
	gap: 17px;
	margin-bottom: 20px;
	margin-top: 10px;
}

.formField .chatBot_request_field {
	background-color: var(--dashboard-bg);
	height: 50px;
	line-height: 50px;
	border: none;
}

.chatbot_container {
	display: flex;
	flex-direction: column;
	align-content: space-between;
	overflow-y: scroll;
	scrollbar-width: none;
	-ms-overflow-style: none;
	max-width: 1090px;
	width: 100%;
}

.chatbot_messages {
	padding-bottom: 200px;
	margin-top: 20px;
}

.chatbot_messages h4 {
	margin-bottom: 10px;
}

.start_questions{
	transition: all 0.5s ease;
}

.start_questions:hover {
	background-color: var(--second-logo-color);
	cursor: pointer;
}


.backLink {
	position: absolute;
	top: 140px;
	left: 30px;
	z-index: 999;
}

.chatbot_message__files_wrap {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-top: 20px;
	
}

.chatbot_message__file {
	background-color: var(--modal-gray);
	border: 2px solid var(--main-dark);
	border-radius: 10px;
	padding: 27px 32px;
	font-size: 24px;
	font-weight: 500;
	display: flex;
	justify-content: space-between;
}

.chatbot_message__file:hover {
	background-color: var(--light-grey);
	cursor: pointer;
}

.type {
	border-radius: 20px;
	border: 1px solid var(--main-dark);
	padding: 3px 20px;
	font-size: 14px;
}

@media screen and (max-width: 1240px) {
	.chatbot_container {
		margin-top: 10px;
	}
}

@media screen and (max-width: 940px) {
	.chatbot_container {
		margin-top: 90px;
	}

	.backLink {
		top: 100px;
	}
}
@media screen and (max-width: 740px) {
	.chatbot_input_form{
		padding: 20px 0;
	}
	
	.chatbot_input_form button{
		display: none;
		
	}
}