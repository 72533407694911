.hiw_block_steps {
	display: grid;
	max-width: calc( var(--max-container-width ) - 5%);
	margin: 20px auto 130px;
	padding: 80px;
	border: 2px solid var(--main-dark);
	border-radius: 30px;
	background: var(--green-500);
	grid-template-columns: 35% calc(100% - 35% - 60px);
	gap: 60px;
}

.hiw_block_steps_image {
	display: flex;
	flex-direction: column;
	gap: 50px;
}

.hiw_block_steps_image img {
	max-width: 170px;
}

.hiw_block_steps_steps  span {
	position: relative;
	z-index: 1;
	width: 82px;
	height: 82px;
	text-align: center;
	vertical-align: middle;
	border-radius: 50%;
	background-color: var(--third-logo-color);
	font-family: var(--primary-font);
	font-size: 57px;
	font-weight: 500;
	font-style: normal;
	line-height: 31px;
	line-height: 82px;
	display: grid;
	align-items: center;
	justify-items: center;
}

.hiw_block_steps_steps li {
	position: relative;
	display: grid;
	padding-bottom: 80px;
	grid-template-columns: auto 1fr;
	gap: 30px;

}

.hiw_block_steps_steps li::after {
	position: absolute;
	z-index: 0;
	top: 15px;
	left: 30px;
	width: 23px;
	height: 100%;
	content: '';
	background-color: #95E7B3;
}

.hiw_block_steps_steps li:last-child {
	padding-bottom: 0;
}

.hiw_block_steps_steps li:last-child::after {
	display: none;
}

.hiw_block_steps_steps h5 {
	margin-bottom: 20px;
}

.hiw_block_steps_steps p {
	font-family: var(--primary-font);
	font-size: 22px;
	font-weight: 500;
	font-style: normal;
	line-height: 31px;
}

.hiw_block_steps_steps a {
	color: var(--third-logo-color);
	font-size: 19px;
	font-weight: 400;
	line-height: 31px;
}

.hiw_block_steps_steps a::after {
	background-color: var(--third-logo-color);
}

.hiw_block_steps_steps a.black_link{
	color: var(--main-dark);
	font-size: 22px;
	font-weight: 500;
	font-style: normal;
	line-height: 31px;
	cursor: pointer;
}

.hiw_block_steps_steps a.black_link::after {
	background-color: var(--main-dark);
	transform: scaleX(1);
	transform-origin: bottom left;
	
}

.hiw_block_steps_steps a.black_link:hover::after {
	transform: scaleX(0);
	transform-origin: bottom right;
}
@media screen and (max-width: 1024px) {
	.hiw_block_steps {
		margin-top: 0;
		padding: 30px 25px;
		border: 0;
		border-radius: 0;
		grid-template-columns: 1fr;
		gap: 30px;
	}

	.hiw_block_steps_steps li::after {
		top: 40px;
	}

	.hiw_block_steps_steps li:first-child span {
		align-self: start;
	}
}

@media screen and (max-width: 768px) {
	.hiw_block_steps {
		margin-bottom: 0;
	}
	
	.hiw_block_steps_steps  span {
		width: 60px;
		height: 60px;
		font-size: 35px;
		line-height: 60px;
	}

	.hiw_block_steps_steps li::after {
		left: 23px;
		width: 15px;
	}

	.hiw_block_steps_steps li { 
		gap: 14px;
		padding-bottom: 38px;
	}

	.hiw_block_steps_steps p,
	.hiw_block_steps_steps a,
	.hiw_block_steps_steps a.black_link {
		font-size: 18px;
		line-height: 25px;
	}


	.hiw_block_steps_steps h5 {
		font-size: 25px;
		margin-bottom: 10px;
	}
}