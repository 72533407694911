.testimonialsWrapper {
	display: flex;
	align-items: center;
	overflow: hidden;
	position: relative;
	flex-direction: column;
}

.testimonialsInner {
	width: 100%;
	height: 100%;
	display: flex;
	position: relative;
	box-sizing: border-box;
	margin: 60px -15px 80px;
	padding: 0;
	transform-style: flat;
	backface-visibility: hidden;
	transition: transform 400ms ease 0ms;
}

.testimonial_item {
	padding: 0 15px;
}

.testimonial_container {
	display: grid;
	border: 2px solid var(--main-dark);
	padding: 30px;
	border-radius: 30px;
	height: 100%;
	align-content: space-between;
}

.testimonial_container p {
	font-weight: 500;
}

.meta {
	display: grid;
	gap: 10px;
}

.testimonial_item .author {
	font-size: 22px;
	font-weight: 400;
	
}

.dots {
	list-style: none;
	display: flex;
	gap: 10px;
}

.dots li {
	width: 13px;
	height: 13px;
	background: var(--first-logo-color);
	border-radius: 50%;
	transition: all 0.4s ease;


}

.dots li:hover {
	background: var(--third-logo-color);
	cursor: pointer;

}

.dots li.active {
	background: var(--third-logo-color);
}

@media screen and (max-width: 1024px) {
	.testimonial_item {
		gap: 30px;
	}
}