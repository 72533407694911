.datePickerAI {
	display: flex;
	gap: 10px;
}

.btnLeft {
	justify-self: start;
}

.charts {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 30px;
}

.analytics_members_charts {
	display: grid;
	gap: 30px;
}

.chart_item {
	padding: 20px;
	background-color: var(--dashboard-bg);
	overflow: hidden;
}

.chart_title {
	border-bottom: 1px solid var(--main-dark);
	padding-bottom: 10px;
}

.chart_item:first-child {
	grid-column-start:1 ;
	grid-column-end: -1 ;
}

.chartVariety {
	display: flex;
	gap: 10px;
}

@media screen and (max-width: 1024px) {
	.charts {
		grid-template-columns: 1fr ;
		gap: 30px;
	}
}