.left_part {
	display: grid;
	gap: 30px;
}

.right_part  {
	font-weight: 500;
	display: grid;
	gap: 30px;
}

.right_part form p,
.right_part form p a{
	font-family: var(--primary-font);
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: 17px;
}

.emailGroup {
	display: grid!important;
	grid-template-columns: 1fr 160px;
	margin-bottom: 20px!important;
}

.emailGroup input {
	background: var(--input-bg);
	border: 0;
	border-top-right-radius: 0!important;
	border-bottom-right-radius: 0!important;
	height: 60px;
}

.emailGroup button {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	color: var(--first-logo-color);
	font-size: 22px;
	font-weight: 400;
	padding: 0 28px;
}

@media screen and (max-width: 768px){
	.left_part {
		display: none;
	}
	
	.emailGroup {
		grid-template-columns: 1fr;
		gap: 15px;
		justify-items: center;
	}

	.emailGroup input {
		border-top-right-radius: 10px!important;
		border-bottom-right-radius: 10px!important;
	}

	.emailGroup button {
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
		padding: 10px 28px;
	}
}