.templateHeader {
	max-width: 80%;
	margin: 0 auto;
	padding: 30px 0;
}

.templatePage {
	padding-top: 60px;
}

.templatePage h1, .templatePage h1 span {
	font-family: var(--primary-font)!important;
	font-weight: 500!important;;
	font-style: normal;
	margin-bottom: 20px;
	font-size: 50px;
}

.templatePage h2, .templatePage h2 span {
	font-family: var(--primary-font)!important;
	font-weight: 500!important;;
	font-style: normal;
	margin-bottom: 20px;
	font-size: 37px!important;
	background-color: transparent!important;
}

.templatePage h3, .templatePage h3 span {
	font-weight: 500!important;
	font-family: var(--primary-font)!important;
	font-size: 30px!important;
	color: var(--main-dark)!important;
	background: transparent!important;
}

.templatePage h3 {
	margin-bottom: 15px;
}

.templatePage strong {
	font-weight: 500;
}

.templatePage h4, .templatePage h4 span {
	font-weight: 500!important;
}

.templatePage p, .templatePage p span{
	font-family: var(--primary-font)!important;
	font-style: normal!important;
	font-weight: 400!important;
	font-size: 22px!important;
	color: var(--main-dark)!important;
	line-height: 32px!important;
	background:transparent!important;
}

.templatePage p {
	margin-bottom: 15px;
}

.templatePage p a {
	color: var(--middle-blue);
	font-weight: 400!important;
	font-size: 22px!important;
	transition: all 04s ease;
}

.templatePage p a {
	position: relative;
	cursor: pointer;
}

.templatePage p a::after {
	content: '';
	position: absolute;
	width: 100%;
	transform: scaleX(0);
	height: 1px;
	bottom: 0;
	left: 0;
	background-color: var(--middle-blue);
	transform-origin: bottom right;
	transition: transform 0.25s ease-out;
}

.templatePage p a:hover::after {
	transform: scaleX(1);
	transform-origin: bottom left;
}

.templatePage ul {
	margin: 40px 0;
}

.templatePage ol {
	list-style:decimal;
}

.templatePage ol li {
	margin-bottom: 10px;
}

.templatePage ol li::marker {
	font-weight: 400!important;
	font-size: 22px!important;
	line-height: 32px!important;
	background-color: transparent!important;
}

.templatePage ul li {
	margin-bottom: 20px;
	padding-left: 40px;
	background: url('./Arrow-Text.svg') no-repeat 0 6px;
	font-family: var(--primary-font)!important;
	font-size: 22px!important;
	font-weight: 400;
	font-style: normal;
	line-height: 31px;
	margin-left: 80px;
	color: var(--main-dark)!important;
}

.templatePage  li span {
	font-weight: 400!important;
	font-size: 22px!important;
	font-family: var(--primary-font)!important;
	line-height: 28px!important;
	background-color: transparent!important;
}

.templatePage ul li b {
	font-weight: 500;

}

.templatePage  table {
	border: 1px solid var(--main-dark);
	border-spacing: 0;
	border-right: 0;
	border-bottom: 0;
	margin-bottom: 30px;
	margin-top: 30px;
	width: 100%;
}

.templatePage  table td {
	border-bottom:  1px solid var(--main-dark);
	padding: 10px;
	border-right: 1px solid var(--main-dark);
}

.templatePage  table td p {
	margin-bottom: 0;
}

.templatePage  table thead th {
	background-color: var(--first-logo-color);

	/* color: var(--white); */
	border-bottom: 1px solid var(--main-dark);
	border-right: 1px solid var(--main-dark);
	padding: 6px 10px;
}

.templatePage  table thead th p,
.templatePage  table thead th p span {
	margin-bottom: 0;
	vertical-align: middle;
	font-weight: 500;
}

.coral_bg {
	background-color: var(--first-logo-color);
}

.aboutUs {
	padding: 60px 0;
}

.aboutUs_container {
	max-width: 80%;
	margin: 0 auto;
	display: grid;
	gap: 40px;
	grid-template-columns: 1fr 1fr;
	justify-items: center;
	align-items: center;
}

.templateContent img, .aboutUs_container img {
	grid-row: 1 / 2;
	grid-column: 2 / 2;
	max-width: 400px;
}

.templateContent {
	display: grid;
	padding: 40px 0;
	grid-template-columns: 1fr 1fr;
	max-width: 80%;
	margin: 0 auto;
	gap: 70px;
}

.formContainer {
	max-width: 80%;
	margin: 0 auto;
	display: grid;
}

.formContainer  form {
	display: grid;
	margin-bottom: 30px;
}

.teamWrap {
	position: relative;
}

.circle {
	border-radius: 50%;
	width: 200px;
	height: 200px;
	position: absolute;
	z-index: 0;
	background-color: var(--first-logo-color);
	left: 100px;
	top: 50px;
}

.team_photo {
	position: relative;
	z-index: 1;
	display: block;
}

.meantime {
	background-color: var( --text-highlight);
	padding: 40px 0;
	margin-bottom: 30px;
}

.meantime p {
	max-width: 60%;
	text-align: center;
	font-size: 30px;
	margin: 0 auto;
	line-height: 50px;
}

.postsList {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 30px;
	max-width: calc(var(--max-container-width) - 3%);
	margin: auto;
	padding: 0 15px 30px;
}

@media screen and (max-width: 768px) {
	.templatePage {
		padding-top: 30px;
	}

	.templatePage ul li {
		margin-left: 0;
		margin-bottom: 0;
	}

	.templatePage h2,
	.templatePage h2 span {
		font-size: 30px!important;
	}

	.templateContent {
		grid-template-columns: 1fr;
		max-width: 100%;
		padding: 0;
		gap: 20px;

	}

	.meantime {
		padding: 20px 0;
	}

	.meantime p {
		font-size: 22px;
		line-height: 30px;
		max-width: 80%;
	}

	.postsList {
		grid-template-columns: 1fr;
	}

	.pdfImg img {
		max-width: 80%;
	}

	.formContainer {
		margin-top: 0;
		max-width: 90%;
	}

	.aboutUs_container {
		grid-template-columns: 1fr;
		max-width: 100%;
		padding: 0 40px;
	}

	.aboutUs_container img {
		max-width: 90%;
		grid-row: 2 / 2;
		grid-column: 1 / 2;
	}
}