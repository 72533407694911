.onboard_item {
	display: flex;
	align-content: stretch;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	padding-top: 20px;
	gap:20px;
}

.onboarding_header {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 15px;
}

.step_color {
	color: var(--white);
}

.onboard_item button {
	min-width: 200px;
}

.left {
	display: inline-grid;
	text-align: left;
}

.left label:last-child {
	margin-bottom: 0;
}

.left label span{
	font-family: var(--primary-font);
	font-size: 18px;
	font-weight: 400;
	line-height: 22px;
}

.left label a {
	font-size: 15px;
	font-weight: 400;
	line-height: 22px;
}

.form_group p,
.form_group p a {
	font-size: 18px;
	line-height: 25px;
	font-weight: 400;
}

.form_group p small a {
	font-size: inherit;
}

.left label a:hover {
	text-decoration: none;
}

.left label span:first-child {
	margin-top: -1px;
}

.checkboxGroup {
	max-width: 78%;
}

strong {
	font-weight: 500;
}

.form_group {
	display: grid;
	gap: 22px;
	width: 100%;
}

.stepMark {
	display: block;
	width: 43px;
	height: 43px; 
	text-align: center;
	color: var(--white);
	border-radius: 50%;
	background: var(--main-dark);
	font-size: 24px;
	font-weight: 500;
	font-style: normal;
	line-height: 43px;
}

.form_oneline {
	display: flex;
	gap: 20px;
}

.buttonGroup {
	display: flex;
	gap: 20px;
}

.form_fullField {
	width: 70%;
}

p.strong {
	font-size: 18px;
	font-weight: 500;
	line-height: 25px;
}

.create_new_account{
	font-size: 18px;
	margin-top: 24px;
}

.create_new_account_link {
	cursor: pointer;
}

.resendLink {
	cursor: pointer;
}

@media screen and (max-width:1100px) {
	.checkboxGroup {
		max-width: 100%;
	}
}
@media screen and (max-width: 760px) {
	.checkboxGroup {
		max-width: 100%;
	}

	.onboard_item h3 {
		margin-bottom: 0;
		font-size: 22px;
	}

	.onboard_item img {
		max-height: 95px;
	}

	.onboard_item button {
		min-width: 123px;
	}

	.left {
		margin-top: 5px;
	}

	.buttonGroup {
		margin-top: 0;
		
	}

	.form_fullField {
		width: 100%;
	}

	.form_oneline {
		flex-direction: column;
		width: 100%;
	}
}