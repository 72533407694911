.timeLine_item
{
	/* display: flex; */
	align-items: flex-start;
	align-content: flex-start;
	justify-content: flex-start;
	position: relative;
	width: 100%;
	justify-items: start;
	flex-wrap: wrap;
}

.timeLine_item::after {
	content: '';
	width: 100%;
	height: 9px;
	background-color: var(--third-logo-color);
	top: 17px;
	left: 15px;
	position: absolute;
	z-index: 0;
}

.finishedItem::after  {
	background-color: var(--main-dark);
}

.timeLine_number {
	width: 50px;
	height: 50px;
	line-height: 50px;
	font-size: 30px;
	font-style: normal;
	font-weight: 500;
	color: var(--white);
	margin-bottom: 10px;
	position: relative;
	z-index: 1;

}

.finished {
	background: var(--main-dark)!important;
}

.timeLine_item a {
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	text-decoration: underline;
	text-underline-offset: 3px;
	text-decoration-thickness: 1px;
	max-width: 100%;
	line-height: 30px;
	transition: all 400ms;
}

.timeLine_item a:hover {
	text-decoration: none;
	
}

@media screen and (max-width: 1024px) {
	.timeLine_item::after {
		top: 11px;
	}
}

@media screen and (max-width: 935px) {
	.timeLine_item a {
		font-size: 16px;
		line-height: 22px;
	}

}
@media screen and (max-width: 768px) {
	.timeLine_item  {
		grid-template-columns: auto 1fr;
		gap: 14px;
		align-items: center;
		display: grid;
	}

	.timeLine_item::after {
		width: 9px;
		height: 100%;
		left: 11px;
		top: 25px;
	}

	.timeLine_item:last-child::after {
		display: none;
	}

	.timeLine_number {
		width: 30px;
		height: 30px;
		font-size: 16px;
		margin-bottom: 0;
	}

	.timeLine_item a {
		font-size: 16px;
		padding: 5px 0;
	}
}