
.loader {
	position: absolute;
	top: 50%;
	left: 50%;
}


.filterWrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.filterWrap span {
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	cursor: pointer;
	font-family: var(--primary-font);
}

.tagsList {
	display: flex;
	gap: 5px;
	margin-top: 10px;
}

.tag {
	border-color: var(--main-dark)!important;
	cursor: pointer;
}

.tag:hover {
	background: var(--main-dark)!important;;
	color: var(--white)!important;;
}


@media screen and (max-width: 768px) {
	.guidesWrap {
		overflow: hidden;
	}
	.filterWrap span {font-size: 12px;}
}