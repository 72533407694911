.Card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 30px;
	text-align: center;
	/* border: 2px solid var(--main-dark); */
	box-shadow: 0 2px 3px 1px var(--shadow);
	border-radius: 15px;
	gap: 15px;
}

.Card a {
	transition: all 0.2s;
}

.Card a:hover {
	text-decoration: none;
}

.guide_card {
	background: var(--modal-gray);
	width: 100%;
	height: 100%;
	padding: 0;
	display: grid;
	grid-template-columns: 1fr;
	overflow: hidden;
	justify-items: center;
	position: relative;
	transition: all 0.4s ease-in;
	top: 0;
}



.guide_card:hover {
	cursor: pointer;
	box-shadow: 0 3px 5px 1px var(--shadow);
	top: -10px;
}


@media screen and (max-width: 768px) {
	.Card {
		padding: 20px;
	}

	.guide_card {
		padding: 0;
	}
}