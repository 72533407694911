.wellbeingWrap {
	padding-bottom: 80px;
}

.spaceHeader{
	display: grid;
	grid-template-columns: auto 1fr;
	gap: 0 14px;
}

.mainTitleWellbeing {
	border-bottom: 2px solid var(--main-dark);
	padding-bottom: 20px;
}

.spaceHeader svg {
	width: auto;
	height: 60px;
	grid-row: 1 / 3;
}

.spaceHeader  h2 {
	text-transform: capitalize;
}

.wellbeing_explain_text {
	font-style: normal;
	font-weight: 500;
	font-size: 30px;
	line-height: 40px;
	margin:  30px 0;
}

.WellBeingChallenges {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 30px;
	justify-content: center;
}

.WellBeingChallenges > a {
	max-width: 500px;
}

.challenge_item {
	height: 100%;
	padding: 20px;
	padding-bottom: 110px;
	position: relative;
	overflow: hidden;
	top: 0;
	transition: all 0.2s ease-in;
}

.challenge_item:hover {
	box-shadow: 0 3px 5px 1px #A6A5A5;
	top: -5px;
}

.item_feel {
	background-color: var(--lime);
}

.item_challenge {
	background-color: var(--green-500);
}

.chalangeImg img {
	max-height: 200px;

}

.emoji img {
	max-width: 370px;	
	margin-top: 40px;
	width: 100%;
}

.card_title {
	background: var(--dashboard-bg);
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 0 25px;
	font-weight: 500;
	font-size: 30px;
	line-height: 30px;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-top: 2px solid var(--main-dark);
}

.filling_title {
	font-weight: 500;
	font-size: 30px;
	line-height: 40px;
	text-align: center;
}

.fillingsMenu {
	position: relative;
}

.ciclegraph {
	position: relative;
	width: 750px;
	height: 750px;
	transform: translate(-50px, 0);
	margin: 0 auto;
	margin-bottom: 280px;
}

.ciclegraph::before {
	content: "Do any of these feelings resonate with you today?";
	position: absolute;
	top: 50%;
	left: 50%;
	font-style: normal;
	font-weight: 500;
	font-size: 30px;
	line-height: 40px;
	text-align: center;
	transform: translate(-34%, 21%);
}

.ciclegraph .circle {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 220px;
	height: 220px;
	margin: calc(-100px / 2);
	border: 2px solid var(--main-dark);
	display: flex;
	align-items: center;
	justify-content: center;
	font-style: normal;
	font-weight: 500;
	font-size: 30px;
	line-height: 30px;
	cursor: pointer;
	border-radius: 50%;
	transition: all 1s ease, box-shadow 0.2s ease-in;
}

.ciclegraph .circle:hover {
	box-shadow: 0 3px 5px 1px #A6A5A5;
}

.color {
	color: blue;
}

.anger {
	background-color: #F8815C
}

.shock {
	background-color: #CA9785
}

.guilt {
	background-color: #6DC3D6
}

.loneliness {
	background-color: #96CEC5
}

.sadness {
	background-color: #CADBAF
}

.stress {
	background-color: #DEE1A7
}

.subPageHeader svg {
	width: auto;
	height: 35px;
}

.challenges_list li {
	padding: 0;
}

.challengeList {
	display: grid;
	grid-template-columns: minmax(auto, 647px);
	margin-top: 20px;
	gap: 30px;

}

.toolsRecommendation {
	padding-bottom: 50px;
	margin-bottom: 10px;
	border-bottom: 2px solid var(--main-dark);
}

.challengeTitle {
	display: inline-block;
	background: var(--coral-second);
	width: 100%;
	text-align: center;
	border: 2px solid var(--main-dark);
	padding: 19px 0;
	border-radius: 15px;
	font-weight: 500;
	font-size: 25px;
	line-height: 32px;
	transition: all 0.2s ease;
}

.challengeTitle:hover {
	background-color: transparent;
}

.sourceItem .challengeTitle {
	font-weight: 400;
	font-size: 19px;
	line-height: 25px;
	text-align: left;
	padding: 25px 30px;
}

.sourceItem .challengeTitle:hover {
	background-color:  var(--green-500);
}

.showMore {
	font-weight: 400;
	font-size: 17px;
	line-height: 25px;
	cursor: pointer;
}

.challenges_list .showMore::after {
	transform: scaleX(1);
	transform-origin: bottom left;
}

.challenges_list .showMore:hover::after {
	transform-origin: bottom right;
	transform: scaleX(0);
}

.challenges_list_details h4 {
	margin-top: 50px;
	vertical-align: middle;
	display: flex;
	gap: 10px;
	margin-bottom: 15px;
}

.challenges_list_details h4 svg {
	width: auto;
	height: 26px;
}

.feeling_desc {
	font-weight: 400;
	font-size: 22px;
	line-height: 31px;
}

.wellbeing_explain_text .accordionData div{
	border: 0;
	padding-left: 0;
	padding-right: 0;
}

.accordionData div h5{
	font-style: normal;
	font-weight: 500;
	font-size: 30px;
	line-height: 40px;
}

.accordionData div h5 + svg {
	min-width: 40px;
	margin-left: 10px;
}

.feeling_desc  span {
	font-size: 22px!important;
	line-height: 31px!important;
	font-family: var(--primary-font)!important;
}

.challengeModal {
	padding: 65px 65px 40px;
	display: grid;
	gap: 30px;
}

.challengeModal p {
	font-size: 19px;
	line-height: 31px;
}

.button {
	justify-self: center;
}

.article  {
	margin-top: 30px;
}

.article h3 {
	display: flex;
	align-items: center;
}

.article .addToBook {
	font-size: 40px!important;
	margin-left: 15px;
}

.addToBook:hover {
	cursor: pointer;
	opacity: 0.7;
}

.recommendations {
	margin-top: 54px;
	margin-bottom: 30px;
}

.recommendations h3 {
	margin-bottom: 32px;
}

.recomendsItem {
	border-bottom: 2px solid var(--main-dark);
	padding-bottom: 25px;
}

.recomendsItem h4,
.recomendsItem h4 a{
	font-size: 22px;
	margin-top: 18px;
	display: flex;
	gap: 10px;
	transition: all 0.4s ease;
	line-height: 30px;
}

.recomendsItem h4 a:hover {
	color: var(--middle-blue);
}

.recomendsItem h4 a svg{
	min-width: 35px;
	
}

.recomendsItem h4 a path {
	transition: all 0.6s ease;
}

.recomendsItem h4 a:hover path {
	stroke:  var(--middle-blue);
}

.filter {
	font-size: 22px;
	display: flex;
	align-items: center;
	font-weight: 500;
	line-height: 31px;
	letter-spacing: -0.22px;
	gap: 15px;
}

.selectFilter {
	margin: 3px 0 0!important; 
}

.recomendsItem p {
	background-color: transparent;
	font-size: 17px;
	font-weight: 500;
}

.recomendsItem p span,
.recomendsItem h3 span{
	background-color: transparent!important;
	font-size: 17px!important;
	font-family: var(--primary-font)!important;
}


.inputSelect  div{
	font-size: 22px!important;
	font-family: var(--primary-font)!important;
	font-weight: 500!important;
	line-height: 25px!important;
	letter-spacing: -0.22px;
	min-height: 25px!important;
	
}

.inputSelect  input[type="checkbox"] ~ span  {
	display: none;
}

.componentNotFound {
	height: auto;
}

@media screen  and (max-width: 1050px) {
	.WellBeingchallenges {
		grid-template-columns: 1fr;
	}
}

@media screen and (max-width: 768px) {
	.wellbeing_explain_text {
		margin-top: -30px;
	}

	.wellbeing_explain_text p {
		font-size: 16px;
		line-height: 22px;
	}

	.wellbeingWrap {
		padding-bottom: 0;
		padding-top: 40px;
	}

	.challenge_item svg {
		max-width: 270px;
		max-height: 130px;
	}

	.WellBeingChallenges {
		grid-template-columns: 1fr;
	}

	.challengeModal  {
		padding: 0;
		gap: 15px;
	}

	.challengeModal  h3 {
		margin-bottom: 0;
		font-size: 22px;
		line-height: 30px;
	}

	.challengeModal  p {
		font-size: 18px;
		line-height: 25px;
	}

	.ciclegraph {
		width: 100%;
		height: auto;
		transform: translate(0, 0);
		height: auto;
		margin-bottom: 0;
	}

	.ciclegraph::before {
		position: relative;
		top: 0;
		left: 0;
		margin-bottom: 20px;
		display: block;
		transform: translate(0, 0);
		font-size: 22px;
		line-height: 29px;
	}

	.ciclegraph .circle {
		position: relative;
		border-radius: 10px;
		left: 0;
		width: 100%;
		margin: 0;
		top: 0;
		transform: rotate(0deg) translate(0) rotate(0deg)!important;
		border: 0;
		margin-bottom: 20px;
		height: auto;
		padding: 20px;
	}

	.challengeList {
		gap: 10px;
	}

	.filter {
		flex-direction: column;
		align-items: flex-start;
	}

	.selectFilter {
		width: 100%;
	}

	.card_title {
		font-size: 22px;
	}

	.accordionData div h5 {
		font-size: 22px;
		line-height: 29px;
	}

	.mainTitleWellbeing {
		font-size: 25px;
		padding-bottom: 10px;
	}

	.challenges_list_details h4 {
		margin-top: 20px;
		margin-bottom: 0;
	}

	.sourceItem .challengeTitle {
		padding: 10px 15px;
	}

	.toolsRecommendation {
		margin-bottom: 0;
		padding-bottom: 30px;
	}

	.recommendations h3 {
		margin-bottom: 10px;
	}

	.ciclegraph .circle { 
		font-size: 25px;
		padding: 15px;
	}
}


@media screen and (max-width: 768px) {
	.wellbeingWrap {
		overflow: hidden;
	}

	.guidesWrap {
		overflow: hidden;
	}
	
	.filterWrap span {font-size: 12px;}

}