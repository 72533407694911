

.cellButtons {
	display: flex;
	justify-content: space-between;
}

.tableHead {
	background-color: var(--main-dark);
	color: var(--white)
}

.tableHead  th {
	color: var(--white)
}

.deleteConfirmDialog .deleteConfirmBtn{
	padding: 0 40px 40px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 30px;
}

.deleteConfirmDialog .deleteConfirmText {
	font-size: 30px;
	font-weight: 500;
	padding-top: 40px;
}
