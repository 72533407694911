.textarea {
	width: 100%;
	height: 255px;
	padding: 17px 28px;
	resize: none;
	border: 2px solid var(--main-dark);
	border-radius: 10px;
	outline: none;
	background-color: transparent;	font-family: var(--primary-font);
	font-size: 19px;
	font-weight: 400;
	line-height: 22px;
}

.textarea::placeholder {
	color: var(--light-grey);
    
}

.textarea.has_error::placeholder {
	color: var(--error);
}

.errorText {
	margin: 3px 14px 0;
	text-align: left;
	letter-spacing: 0.0333em;
	color: var(--error);
	font-family: Roboto,Helvetica,Arial,sans-serif;
	font-size: 0.75rem;
	font-weight: 400;
	line-height: 1.66;
}