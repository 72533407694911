.label{
	position: relative;
}

.visibilityIcon {
	position: absolute;
	top: 10px;
	right: 10px;
	transition: all 0.4s ease;
}

.visibilityIcon:hover {
	cursor: pointer;
	color: var(--middle-blue);
}

