.formWrap {
	margin-bottom: 50px;
	padding: 20px 10px 10px;
	border-radius: 10px;
	background-color: #fff;
}

.title {
	margin-bottom: 20px!important;
	margin-top: 20px!important;
}

.wyswyg_editorWrap {
	margin-bottom: 30px;
}

.editorTextarea {
	height: 250px;
	padding: 15px;
}

.formField {
	display: grid;
	gap: 20px;
	grid-template-columns: auto 1fr;
	margin-bottom: 20px;
}

.selectsWrap {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 30px;
	margin-bottom: 20px;
}



.selects label {
	background-color:  #ffffff!important;
	padding: 2px 22px!important;
}

.selects div div > div{
	padding: 13px 22px!important;
}

.reading_time {
	margin-bottom: 10px!important;
}

.need_journal {
	margin-left: 10px!important;
}

.previewLink {
	margin-bottom: 10px;
}
