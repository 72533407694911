.btn {
	box-sizing: border-box;
	padding: 6px 17px;
	cursor: pointer;
	transition: all 0.2s;
	text-align: center;
	letter-spacing: 0.01em;
	color: var(--main-dark);
	border: 2px solid var(--main-dark);
	border-radius: 10px;
	font-family: 'GT Walsheim', Arial, sans-serif;
	font-size: 15px;
	font-weight: 500;
	line-height: 17px;
	gap: 10px;
	display: flex;
	align-items: center;
	justify-content: center;

}

.btn.center {
	margin: 0 auto;
}

.btn.big {
	font-size: 22px;
	padding: 22px 28px;
}

.primary {
	background-color: var(--middle-blue);
}

.primaryLight {
	background-color: var(--second-logo-color);
}

.secondary {
	background-color: var(--third-logo-color);
	border-color: transparent;
	
}

.warning {
	background-color: var(--first-logo-color);
}

.broun {
	background-color: var(--light-broun);
}

.error {
	background-color: var(--first-logo-color);
}

.dark {
	color: var(--modal-gray);
	background-color: var(--main-dark);
}

.default{
	background-color: transparent;
}

.disable {
	background-color: transparent;
	cursor: default ;
	border: 2px solid var(--main-dark);
}

.btn:hover {
	color: var(--main-dark);
	background-color: transparent;
	
}

.btn.secondary:hover {
	border: 2px solid var(--main-dark);
}

.default:hover {
	color: var(--modal-gray);
	background-color: var(--main-dark);
}

.white {
	color: var(--white);
	border-color: var(--white);
	background-color: transparent;
}

.white:hover {
	border-color: var(--white);
	background: var(--white);
}

.secondaryDark {
	color: var(--third-logo-color);
	border-color: var(--third-logo-color);
	background-color: transparent;
}

.secondaryDark:hover  {
	background-color: var(--third-logo-color)

}

.middle {
	font-size: 19px;
	padding: 14px 20px;
}