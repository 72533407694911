.title {
	margin-bottom: 20px!important;
}


.previewLink {
	margin: 20px 0;
	
}

.previewLink  a {
	font-size: 20px;
}

.wyswyg_editorWrap {
	margin-bottom: 30px;
}

.wyswyg_editorWrap strong {
	font-weight: 600;
}

.meta {
	display: grid;
	gap: 20px;
}