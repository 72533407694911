.unsubscribeWrap {
	background: url('./Header_Illustration.svg') 110% 10% no-repeat;
	width: 100svw;
	height: 100svh;
	background-size: 60% auto;
}

.unsubscribe_form {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 10px;
	border: 2px solid var(--main-dark);
	padding: 30px;
	background: var(--modal-gray);
	display: grid;
	gap: 20px;
	justify-items: center;
}