.nav_list {
	display: flex;
	gap: 16px;
}

.nav_list li a {
	color: #000;
	text-align: center;
	font-family: "GT Walsheim";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 0.16px;
}

@media screen and (max-width: 935px) {
	.nav_list a {
		font-size: 14px;
	}
}
@media screen and (max-width: 768px) {
	.nav_list {
		position: fixed;
		top:0;
		left: 0;
		flex-direction: column;
		justify-content: start;
		width: 100%;
		height: 100svh;
		padding: 13px 25px;
		padding-top: 80px;
		transition: transform 0.5s ease-in-out;
		z-index: 1;
		transform: translateX(150%);
		background-color: var(--second-logo-color);
		text-align: left;
	}

	.nav_list li a {
		font-size: 25px;
		font-style: normal;
		font-weight: 500;
		line-height: 40px;
		text-align: left;
	}
	
	.nav_list li a::after {
		display: none;
	}
}