
.modalWrap {
	position: absolute;
	top: 50%;
	left: 50%;
	max-width: 800px;
	width: 100%;
	transform: translate(-50%, -50%);
}

.modalStyles {
	scrollbar-width: none;
	-ms-overflow-style: none;
}

.modalStyles::-webkit-scrollbar {
	display: none;
}

.styledBox {
	background-color: var(--dashboard-bg); 
	border-radius: 15px;
	height: 85svh;
	padding: 0!important;
	overflow: hidden;
}

.modalWrap:focus-visible {
	outline: none;
}

.close {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-bottom: 10px;
	margin-left: 3px;
	cursor: pointer;
	color: var(--main-dark);
	gap: 10px;
	transition: all 0.5s ease;
	position: absolute;
	top: 20px;
	right: 20px;
	z-index: 9;
}

.close:hover {
	color: var(--middle-blue);
}

.close svg {
	transform: scale(0.8);
}

.close svg circle,
.close svg line{
	stroke: var(--main-dark);
}

.close:hover svg circle,
.close:hover svg line{
	stroke: var(--middle-blue);
}
@media screen and (max-width: 768px) {
	.modalWrap {
		width: 100%;
		min-height: 100dvh;
	}

	.styledBox {
		height: 100dvh;
		border-radius: 0;
	}

	
}