.history {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10%;
}

.history_left {
	display: grid;
	gap: 30px;
	align-content: space-between;
}

.history_left h1 {
	line-height: 49px;
	font-size: 50px;
}

.history_right {
	display: grid;
	font-size: 22px;
	font-weight: 500;
	line-height: 31px;
	gap: 30px;
}

.history_right  p {
	color: var(--white);
}

.history_right button {
	justify-self: left;
}

.conclusion {
	display: grid;
	max-width: calc( var(--max-container-width ) - 14%);
	text-align: center;
	font-size: 30px;
	font-weight: 500;
	line-height: 40px;
	gap: 30px;
	justify-items: center;
}
@media screen and (max-width: 1265px) {
	.history {
		max-width: 95%;
	}
}
@media screen and (max-width: 1024px) {
	.history {
		grid-template-columns: 1fr;
		max-width: 100%;
		gap: 30px;
	}

}