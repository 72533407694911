.innerPageBlock {
	display: grid;
	margin:0 auto;
	max-width: calc( var(--max-container-width ) - 20%);
}

.innerPageBlock p{
	font-family: var(--primary-font);
	font-size: 30px;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
}

@media screen and (max-width: 768px) {
	.innerPageBlock p {
		font-size: 20px;
		line-height: 30px;
	}
}