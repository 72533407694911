.loginWrapper {
	display: flex;
	align-content: stretch;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	padding-top: 0;
	text-align: center;
	gap:20px;
}

.loginWrapper h3 {
	margin-bottom: 0;
}

.loginWrapper img {
	max-width: 100px;
}

.loginWrapper  button {
	min-width: 200px;
}

.form_group {
	display: grid;
	gap: 10px;
	justify-items: start;
	width: 100%;
	text-align: left;
	
}

.modalContainer  h3 {
	grid-column: 1/-1;
}

.mb25 {
	margin-bottom: 25px;
}

.form_oneline {
	display: grid;
	gap: 16px;
	width: 100%;
}

.form_oneline label {
	font-size: 19px;
	text-align: left;
	margin-bottom: 10px;
	font-weight: 500;
}

.errorText {
	margin: 3px 0 0;
	text-align: left;
	letter-spacing: 0.0333em;
	color: var(--error);
	font-family: Roboto,Helvetica,Arial,sans-serif;
	font-size: 0.75rem;
	font-weight: 400;
	line-height: 1.66;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
	transition: background-color 600000s 0s, color 600000s 0s;
}

.buttonGroup {
	display: flex;
	gap: 20px;
	margin-top: 15px;
}

.login_error {
	margin-left: 22px;
	padding: 7px 23px;
	text-align: left;
	color: var(--error);
	border-radius: 5px;
	background: var(--first-logo-color);
	justify-self: left;
}

.forget_pass {
	cursor: pointer;
	font-size: 13px;
	font-weight: 500;
	line-height: 15px;
	display: flex;
	gap: 24px;
	margin-top: 10px;
}

.forget_pass a {
	font-size: 18px;
	font-weight: 400;
}

@media screen and (max-width: 768px) {
	.form_group button {
		flex-grow: 1;
	}

	.form_oneline {
		display: grid;
	}

	.buttonGroup {
		margin-top: 0;
		width: 100%;
	}

	.forget_pass {
		gap: 10px;
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	.forget_pass a {
		font-size: 19px;
	}
}