.goToTop {
	position: fixed;
	right: 20px;
	bottom: 20px;
	display: grid;
	align-items: center;
	width: 40px;
	height: 40px;
	cursor: pointer;
	transition: opacity 0.2s ease;
	opacity: 0.7;
	border-radius:10px;
	background: var(--middle-blue);
	justify-items: center;
}

.goToTop:hover {
	opacity: 1;
}

.goToTop svg {
	width: 25px;
	fill: var(--white);
}