.dashboardHeader {
	margin-bottom: 20px;
}

.dashboardHeader svg {
	height: 30px;
}

.memberInfoContainer {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto 1fr 1fr;
	gap: 10px 20px;
	width: 100%!important;
	padding: 20px;
	margin: 0 auto;
	border-radius: 10px;
}

.memberInfoContainer p {
	font-size: 18px;
}

.memberInfoContainer_header {
	grid-column: 1 / -1 ;
}

.formsWrap {
	background-color: #fff;
	border-radius: 10px;
	padding: 20px;
	margin-top: 30px;
}

.formField {
	display: grid;
	gap: 20px;
	grid-template-columns: auto 1fr;
	margin-bottom: 20px;
}

.reading_timeField {
	grid-template-columns: auto 1fr 1fr;
}

.editorWrapper {
	background-color: #FFF;
	margin-bottom: 40px;
	border: 2px solid var(--main-dark);
	border-radius: 15px;
	overflow: hidden;
}

.taskViewWrap .main_title {
	color: var(--third-logo-color);
	font-weight: 400!important;
	font-size: 50px!important;
	line-height: 50px;
	letter-spacing: -0.02em;
	font-family: var(--secondary-font)!important;
}

.editorTextarea {
	height: 250px;
	padding: 15px;
}

.tableWrap {
	background-color: #FFF;
}

.headerClassName {
	background-color: var(--main-dark);
	color: var(--white)!important;
	font-weight: 500;
	font-family: var(--primary-font);
}

.headerClassName  svg path {
	stroke: var(--white)
}

.wyswyg_editorWrap {
	margin-bottom: 30px;
}

.selectStatus {
	margin-bottom: 20px!important;
}


.wyswyg_editorWrap strong {
	font-weight: 600;
}

.actions {
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 10px;
}

.actions svg:hover {
	cursor: pointer;
}

.externalLink {
	margin-bottom: 30px;
	cursor: pointer;
	transition: opacity 0.4s ease;
	display: flex;
	align-items: center;
	gap: 10px;
}

.actions .externalLink {
	margin-bottom: 0;
}

.externalLink:hover {
	opacity: 0.3;
}

.previewLink {
	margin-bottom: 20px;
}

.loader {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.spaceName {
	margin-top: 20px;
	margin-bottom: 30px;
}

.task_desc h4 {
	display: flex;
	align-items: center;
	gap: 10px	;
}

.task_desc_content {
	margin-top: 30px;
	line-height: 31px;
}

.task_desc_content p{
	margin-bottom: 15px;	
}

.extraInfo {
	display: grid;
	grid-template-columns: auto 1fr;
	margin-bottom: 45px;
	gap: 30px;
	
}

.extraInfo span {
	display: grid;
	grid-template-columns: auto 1fr;
	font-weight: 500;
	align-items: center;
	font-size: 22px;
	line-height: 31px;
	gap: 10px;
}

.backBtn {
	margin-top: 30px;
}

.actionIcon {
	cursor: pointer;
}

.actionIcon:hover {
	fill: var( --middle-blue)
}

.disabledCheckbox {
	cursor: default!important;

}

.textarea {
	height: 50px;
}