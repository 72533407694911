
.contactBlock {
	max-width: calc( var(--max-container-width ) - 200px);
	margin: auto;
	padding: 125px 15px 60px;
}

.contactBlock input::placeholder {
	font-weight: 500;
}

.form {
	display: grid;
	gap: 30px;
}

.contactBlock label {
	color: var(--light-grey)!important;
	font-weight: 500;
	padding: 7px 0;
}

.contactBlock input {
	color: var(--main-dark)!important;
	font-weight: 500!important;
}

.contact_textBlock {
	margin: 20px 0 120px;
	text-align: center;
	
}

.contact_textBlock p {
	margin-bottom: 20px;
	font-size: 30px;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
}

.form > button {
	justify-self: left;
	min-width: 200px;
	margin: 0!important;
}

.informText {
	font-weight: 500;
	font-size: 20px;
	line-height: 30px;
	text-align: center;
}
@media screen and (max-width: 1240px) {
	.contactBlock {
		max-width: calc( var(--max-container-width ) - 10%);
		
	}

	.contact_textBlock {
		margin: 0 0 40px;
		font-size: 19px;
		line-height: 25px;
	}
}

@media screen and (max-width: 840px) {
	.contactBlock {
		max-width: 95%;
		padding-top: 30px;
	}

	.contactBlock input {
		padding: 18px 15px!important;
	}

	.contactBlock input::placeholder {
		padding: 18px 15px!important;
	}
}

@media screen and (max-width: 768px) {
	.contact_textBlock p {
		font-size: 20px;
		line-height: 29px;
	}
}