.summary {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px 30px;
	margin-top: 20px;
}

h4 {
	margin-bottom: 20px;
}

.summary .label  {
	padding-left: 0!important;
	color: var(--main-dark)!important;
	font-weight: 500!important;
	font-family: var(--primary-font);
	font-size: 22px!important;
}

.summaryWrap {
	border: 3px solid var(--main-dark);
	border-radius: 15px;
	padding: 20px;
	margin-top: 24px;
	background-color: var(--lime);
}

.summaryItem {
	display: flex;
	gap: 14px;
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 25px;
	margin-bottom: 12px;
	align-items: flex-start;
}

.summaryItem:last-child {
	margin-bottom: 0;
}

.summaryItem  svg {
	width: 23px;
	min-width: 23px;
	margin-top: 2px;
}

.summaryItemOption {
	font-weight: 500;
}

@media screen  and (max-width: 768px){
	.summaryItem p {
		font-size: 16px;
		line-height: 21px;
	}
}