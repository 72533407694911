.p404 {
	display: flex;
	align-items: left;
	flex-direction: column;
	justify-content: center;
	gap: 20px;
	width: 95svw;
	height: 100svh;
	text-align: left;
	margin: 0 auto;
}

.p404 p {
	text-align: justify;
}

.buttonGroup {
	display: flex;
	align-items: left;
	justify-content: left;
	gap: 20px;
}