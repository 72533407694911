.styledBox {
	background-color: var(   --dashboard-bg); 
	border: 2px solid var(--main-dark); 
	border-radius: 30px;
	padding: 45px 35px!important;
	letter-spacing: -0.01em!important;
}

.modalStyles {
	scrollbar-width: none;
	-ms-overflow-style: none;
}

.modalStyles::-webkit-scrollbar {
	display: none;
}

.text_under_box {
	margin-top: 15px;
	text-align: center;
	color: var(--light-grey);
	font-size: 14px;
	font-weight: 400;
	line-height: 23px;
}

.text_under_box  a {
	color: var(--light-grey);
	font-size: 14px;
	font-weight: 400;
	line-height: 23px;
}

.modalWrap {
	position: absolute;
	top: 50%;
	left: 50%;
	max-width: 647px;
	width: 100%;
	transform: translate(-50%, -50%);
}

.modalWrap.smallWrap {
	max-width: 425px;
}

.modalWrap.largeWrap {
	max-width: 870px;
}

.modalWrap:focus-visible {
	outline: none;
}

.modalUnstyledWrap button {
	margin-top: 40px;
	align-self: center;
}

.close {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-bottom: 10px;
	margin-left: 3px;
	cursor: pointer;
	color: var(--white);
	gap: 10px;
	transition: all 0.5s ease;
}

.close:hover {
	color: var(--middle-blue);
}


.close svg{
	transition: all 0.4s ease;
}

.close:hover svg{
	transform: rotate(360deg);
}

.close:hover svg circle,
.close:hover svg line{
	stroke: var(--middle-blue);
}
@media screen and (max-width: 768px) {
	.modalWrap {
		width: 95%;
		min-height: auto;
	}

	.modalWrap .styledBox {
		padding: 20px!important;
	}

	.modalWrap.largeWrap {
		max-width: 95%;
	}

}