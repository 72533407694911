.taskCard {
	padding: 15px;
	text-align: left;
	font-weight: 400;
	font-size: 15px;
	line-height: 19px;
	gap: 0;
	background-color: var(--dashboard-bg);
	border: 1px solid var(--main-dark);
}

.taskCardContainer {
	margin-bottom: 12px;
	padding: 0;
}

.taskCard h5 {
	text-transform: capitalize;
	font-weight: 500;
	font-size: 18px;
	line-height: 25px;
}

.taskCard label{
	gap: 10px;
	margin-left: 5px;
	align-items: flex-start;
}

.taskCard label span{
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	font-family: var(--primary-font)!important;
	line-height: 20px;
	margin: 0;
	padding: 0;
}

.taskViewWrap {
	height: 100%;
	overflow-y: scroll;
	display: grid;
	grid-template-rows: auto 1fr auto;
	padding-bottom: 24px;
}

.taskViewHeader {
	background-color: var(--second-logo-color);
	padding: 56px 24px 16px;
}

.task_desc, .task_overview {
	margin: 20px 25px;
}

.task_overview {
	display: grid;
	gap: 10px;
}

.task_desc h4 svg {
	margin-right: 6px;
}

.task_desc_content {
	font-size: 17px!important;
	font-family: var(--primary-font)!important;
	margin-bottom: 10px;
}

.attachmentContainer {
	padding: 18px 23px;
	border: 2px solid var(--main-dark);
	display: grid;
	grid-template-columns: 1fr auto;
	background-color: var(--modal-gray);
	justify-items: start;
	box-shadow: none;
	border-radius: 10px;
	cursor: pointer;
	align-items: center;
}

.attachmentContainer .mailChimp {
	border-color: var(--main-dark);
	text-transform: capitalize;
}

.bottomNav {
	display: flex!important;
	gap: 15px;
	margin: 0 25px!important;
	justify-content: space-between;
}

.bottomNav .taskBtn {
	border: 2px solid var(--main-dark);
	border-radius: 10px;
	text-align: center;
}

.bottomNav .deleteBtn {
	border:2px solid var(--error);
}

.deleteText {
	color: var(--error)
}

.bottomNav .taskBtn {
	flex-grow: unset;
	padding: 4px 16px;
}

.bottomNav .taskBtn div {
	flex: none;
}

.bottomNav .taskBtn span {
	font-size: 18px;
	line-height: 25px;
	font-style: normal;
	font-weight: 400;
	font-family: var(--primary-font);
	padding: 0;
}

.navIcon {
	min-width: auto!important;
	margin-right: 10px;
}

.descField {
	background-color: transparent;
	width: 100%;
	border: 0;
	height: 100%;
	font-weight: 400;
	font-size: 16px;
	font-family: var(--primary-font);
	line-height: 1.5em;
	resize: none;
}

.descField:focus {
	background-color: var(--white);
	outline: none;
	padding: 10px;
}

.shortDesc {
	margin-left: 28px;
}

.shortDesc  > * {
	font-size: 18px!important;
	font-weight: 400;
}

.templates {
	margin: 0 0 15px;
	display: grid;
	gap: 10px;
}

.guide_meta {
	display: flex;
	gap: 15px;
	align-items: center;
}

.guide_meta_time {
	display: flex;
	gap: 5px;
	align-items: center;
	font-size: 12px;
	margin-bottom: 0;
}

.templates h4{
	margin-bottom: 15px;
	display: flex;
	gap: 6px;
}

.templates h4 path {
	stroke: var(--main-dark);
}

.reading_time {
	display: flex;
	gap: 10px;
	margin-top: 15px;
	align-items: center;
	font-weight: 500;
}

.templateItem {
	margin-bottom: 15px;
	display: flex;
	gap: 10px;
	cursor: pointer;
	transition: all 0.4s ease;
	font-size: 17px;
	font-weight: 500;
}

.templateItem:hover {
	color: var(--middle-blue);
}

.activeTitle {
	display: grid;
	grid-template-columns: 18px 1fr;
	gap: 10px;
}

.activeTitle span {
	padding: 0;
	margin: 2px 10px 0 0;
	width: 18px;
	height: 18px;
	display: block;
}

.checkboxChecked span {
	background-color: var(--main-dark);
}

.checkboxChecked span::before{
	filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(1203%) contrast(103%)!important;
}

.activeTitle h3 {
	font-size: 18px;
	line-height: 20px;
	margin-bottom: 5px;
}

.checkbox {
	width: 18px;
	margin-top: 0;
}

@media screen and (max-width: 1460px){
	.taskCard {
		padding: 10px;
		font-size: 15px;
		line-height: 20px;
	}

	.taskCardContainer {
		margin-bottom: 12px;
	}

	.taskCard h5 {
		font-size: 20px;
		line-height: 25px;
	}
}

@media screen and (max-width: 935px){ 
	.activeTitle  h3 {
		font-size: 20px;
		line-height: 21px;
	}
}
@media screen and (max-width: 780px){ 
	.taskTitle {
		font-size: 22px;
		font-style: normal;
		line-height: 24px;
		margin-left: -10px;
	}

	.taskViewHeader {
		padding: 22px 17px 17px;
	}

	.task_desc {
		margin: 20px 25px;
	}

	.commentsWrap {
		margin: 20px;
	}

	.bottomNav {
		grid-template-columns: 1fr 1fr;
		margin: 0 19px!important;
	}
}

@media screen and (max-width: 768px) {
	.taskViewHeader {
		padding-top: 50px;
	}

	.templates {
		margin: 0;
	}

	.commentsWrap h4 {
		margin-bottom: 0;
	}

	.activeTitle h3 {
		font-size: 18px;
		line-height: 25px;
	}

	.shortDesc  > * {
		font-size: 16px!important;
		font-weight: 400;
		line-height: 20px;
	}

	.taskViewWrap {
		padding-bottom: 20px;
	}

	.bottomNav .taskBtn  {
		padding: 10px 20px;
	}

	.bottomNav .taskBtn div {
		margin: 0 5px;
		padding: 0;
	}

	.bottomNav .taskBtn span {
		font-size: 12px;
		margin: 0;
		padding: 0;
		line-height: 20px;
	}

	.attachmentContainer {
		grid-template-columns:  1fr;
		gap: 20px;
		padding: 10px;
	}
}