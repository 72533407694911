
.extraCSWrap {
	width: 100%;
	background-color: #fff;
	border-radius: 10px;
	padding: 20px;
}

.editor {
	position: relative;
	margin-bottom: 20px;
}

/* .extraCSWrap div { */
/* position: static!important; */
/* top: 0; */
/* } */

.card {
	display: flex;
	padding: 10px 20px;
	justify-content: space-between;
	align-items: center;
	font-weight: 500;
}

.button_group {
	display: flex;
	gap: 20px;
}

.problems_user_widget {
	margin-top: 50px;
}

.problemsTitle {
	margin-bottom: 10px;
}

.problemsList {
	list-style-type: none;
	margin: 0;
	padding: 0;
	column-count: 2;
	column-gap: 30px;
	margin-bottom: 40px;
	width: 100%;
	margin-top: 40px;

}

.problem_item {
	padding: 21px 25px;
	transition: all 0.4s ease;
	position: relative;
	top: 0;
	background: var(--green-500);
	border-width: 3px;
	justify-content: flex-start;
	align-items: flex-start;
	-webkit-column-break-inside: avoid;
	-moz-column-break-inside: avoid;
	break-inside: avoid;
	margin-bottom: 30px;
	width: 100%;
}


.problem_item .content {
	font-weight: 400;
	align-items: flex-start;
	text-align: left;
}

.problem_title {
	font-size: 25px;
	font-style: normal;
	font-weight: 500;
	line-height: 25px;
	margin-bottom: 0;
}

.problemList .listItem {
	padding-left: 0;
}

.listTitle {
	display: flex;
	gap: 10px;
}

.content {
	font-size: 19px;
	font-weight: 500;
	margin-top: 0;
	margin-bottom: 20px;
}

.closeLink {
	position: relative;
	cursor: pointer;
}

.closeLink::after {
	content: '';
	width: 100%;
	height: 0;
	border-bottom: 1px solid var(--main-dark);
	position: absolute;
	bottom: -2px;
	left: 0;
}

.wyswyg_editorWrap {
	margin-bottom: 30px;
}

.icons {
	cursor: pointer;
	position: relative;
	display: block;
	text-align: center;
}

.icons svg {
	width: auto;
	height: 26px;
	z-index: 1;
	position: relative;

}

.icons svg path {
	transition: all 0.4s ease;
}

.icons:hover svg path {
	stroke: var(    --middle-blue)
}

@media screen and (max-width: 768px) {
	.problemsTitle {
		font-size: 25px;
	}

	.problemsList {
		column-count: 1;
	}

	.problem_item {
		gap: 5px;
		margin-bottom: 10px;
	}

	.problem_title {
		font-size: 18px
	}

	.content {
		font-size: 16px;
		line-height: 22px;
		margin-top: 10px;
		margin-bottom: 10px;
	}
}