.divorce_pathway_wrap {
	margin: 20px 0 0;
	display: grid;
	gap: 15px;
	margin-top: -65px;
	background-color: var(--modal-gray);
	position: relative;
	box-shadow: 0 0 0 100vmax var(--modal-gray);
	clip-path: inset(0 -100vmax);
	padding: 42px 0;
}

.userPathStatus {
	background-color: var(--third-logo-color);
	justify-content: flex-start;
	position: relative;
	transition: all 0.4s ease-in;
	padding: 26px 36px 36px;
	gap: 23px;
	display: flex;
	align-items: flex-start;
	text-align: left;
}

.userPathStatus:hover {
	cursor: pointer;
	box-shadow: 0 3px 5px 1px var(--shadow);
}

.stepIntroText {
	display: flex;
	gap: 18px;
	font-size: 22px;
	text-align: left;
	line-height: 33px;
}

.stepIntroText img {
	width: 85px;
	height: 85px;
}

@media screen and (max-width: 768px) {
	.divorce_pathway_wrap {
		gap: 12px;
		padding: 20px 0;
		margin-top: -40px;
	}

	.divorce_pathway_subtitle {
		flex-direction: column;
		align-items: flex-start;
	}

	.divorce_pathway_subtitle  button {
		font-size: 12px;
		padding: 8px;
		line-height: 18px;
		margin-bottom: 13px;
	}

	.userPathStatus {
		gap: 15px;
		padding: 19px;
	}

	.userPathStatus h3 {
		text-align: left;
		margin-bottom: 0;
	}

	.stepIntroText img {
		display: none;
	}
}