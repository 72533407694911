.callToAction {
	display: grid;
	max-width: calc( var(--max-container-width ) - 4%);
	margin: 0 auto 30px;
	grid-template-columns: 40% 60%;
	gap: 20px 80px;
}

.innerCallToAction {
	align-content: center;
	align-items: center;
	height: 335px;
	margin: 150px auto;
	padding: 0 55px;
	border: 2px solid var(--main-dark);
	border-radius: 30px;
	background: var(--second-logo-color);
	grid-template-columns: 30% 65%;
	grid-template-rows: auto auto;
	gap: 0 80px;
}

.callToAction_left,
.moreInfo{
	color: var(--first-logo-color);
	font-size: 18px;
	font-weight: 400;
	line-height: 31px;
	
}

.innerCallToAction .callToAction_left {
	grid-row: 1/-1;
}

.moreInfo {
	align-self: end;
}

.moreInfo p {
	color: var(--first-logo-color);
	font-family: var(--primary-font);

}

.moreInfo a {
	color: var(--first-logo-color);
	font-family: var(--primary-font);
	font-weight: 400;
	font-size: 18.3333px;
}

.moreInfo a::after {
	content: '';
	position: absolute;
	width: 100%;
	height: 1px;
	bottom: 0;
	left: 0;
	background-color: var(--first-logo-color);
	transition: transform 0.25s ease-out;
	transform: scaleX(1);
	transform-origin: bottom left;
}

.moreInfo a:hover::after {
	transform: scaleX(0);
}

.callToAction_right h2{
	margin-bottom: 100px;
	font-family: var(--primary-font);
	font-size: 77px;
	font-weight: 500;
	font-style: normal;
	line-height: 72px;

}

.innerCallToAction .callToAction_right {
	align-self: end;
}

.innerCallToAction .callToAction_right h2 {
	font-size: 60px;
	line-height: 60px;
	margin-bottom: 15px;
}

.callToAction_right h2 span {
	color: var(--third-logo-color);
}

.callToAction_right p{
	margin-bottom: 40px;
	font-family: var(--primary-font);
	font-size: 25px;
	font-weight: 400;
	font-style: normal;
	line-height: 32px;
	color: var(--white);
}

.joinBtn button {
	padding: 22px 83px;
}

.joinBtn button:hover {
	background-color: var(--third-logo-color);
}

.innerCallToAction .joinBtn {
	align-self: start;
}

.innerCallToAction .joinBtn button {
	padding: 10px 34px;
	font-size: 18px;
	line-height: 21px;
}

@media screen and (max-width: 1265px) {
	.callToAction,
	.innerCallToAction {
		max-width: 95%;
	}
}
@media screen and (max-width: 935px) {
	.callToAction {
		max-width: 100%;
		gap: 30px;
		grid-template-columns: 1fr;
		grid-template-areas: 
			"callToAction_left"
			"callToAction_right" 
			"joinBtn"
			"moreInfo";
	}

	.innerCallToAction {
		max-width: 100%;
		height: auto;
		margin: 0;
		padding: 25px;
		text-align: center;
		border: 0;
		border-radius: 0;
		gap: 0;
	}

	.callToAction_left {
		grid-area: callToAction_left;
	}

	.innerCallToAction .callToAction_left {
		grid-row: 1/1;
	}

	.callToAction_left svg {
		max-width: 150px;
		max-height: 130px 
	}

	.callToAction_right {
		grid-area: callToAction_right;
	}
	
	.callToAction_right h2{
		margin-bottom: 50px;
		font-size: 51.6667px; 
		font-weight: 500;
		line-height: 46px;
	}

	.joinBtn {
		grid-area: joinBtn;
	}

	.moreInfo {
		font-size: 18px;
		font-weight: 400;
		line-height: 31px;
		grid-area: moreInfo;
	}

	.moreInfo small {
		font-size: 18px;
	}

	.innerCallToAction .callToAction_right h2 {
		font-size: 40px;
		line-height: 43px;
	}
	
	.innerCallToAction .joinBtn button  {
		margin: 0 auto;
	}
}

@media screen and (max-width: 768px) {
	.callToAction_left {
		text-align: center;
	}

	.callToAction_left svg {
		width: 150px;
		height: 100px
	}

	.joinBtn {
		margin: 0 auto;
	}

	.innerCallToAction .callToAction_right h2 {
		font-size: 30px;
		line-height: 33px;
	}
}