.divorceSteps {
	display: flex;
	gap: 10px;
	justify-content: space-between;
}

.nextStep {
	text-align: right;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
}

.nextStep a {
	display: flex;
	align-items: center;
	justify-content: right;
	gap: 10px;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	margin-bottom: 10px;
}

.nextStep a  svg {
	transition: all 0.4s ease;
}

.nextStep a:hover  svg {
	transform: rotate(360deg);
}

@media screen and (max-width: 768px) {
	.divorceSteps {
		flex-direction: column;
		margin-top: 0;
	}

	.guidesWrap {
		overflow: hidden;
	}
}