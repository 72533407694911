.form {
	display: grid;
	gap: 10px;
}

.form p {
	font-size: 20px;
	display: flex;
	align-items: center;
}

.mt20 {
	margin-top: 20px;
}

.mb20 {
	margin-bottom: 20px;
}

.form p label {
	margin: 0;
}

.checkbox_link {
	font-size: 20px;
	font-family: var(--primary-font);
	font-weight: 400;
}