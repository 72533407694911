.footer {
	display: grid;
	color: var(--white);
	background: var(--main-dark);

}

.footer_logo {
	color: var(--white);
}

.footer .wrapper {
	display: grid;
	grid-template-rows: auto 1fr;
	align-items: start;
	grid-template-columns: 1fr 1fr 1fr;
	max-width: var(--max-container-width);
	margin: 0 auto;
	grid-template-areas:
		"aboutUs aboutUsLink contact"
		"aboutUs useful join"
		"copyBlock termsLink termsLink";
	gap: 30px 10%;
	padding: 60px 20px;
}

.footer ul {
	list-style: none;
	font-family: var(--primary-font);
	font-size: 17px;
	font-weight: 500;
	font-style: normal;
	line-height: 27px;
}

.footer h4 {
	padding-bottom: 20px;
}

.footerMenu li {
	padding-bottom: 10px;
}

.termsLink a {
	transition: color 0.3s ease-in;
	text-decoration: none;
	color: var(--middle-blue);
}

.termsLink a.active {
	color: var(--white);
}

.footerMenu li a {
	transition: color 0.3s ease-in;
	text-decoration: none;
	color: var(--white);
}

.footerMenu li a:hover {
	color: var(--third-logo-color);
}

.aboutUs {
	grid-area: aboutUs;
}

.aboutUs p{
	font-family: var(--primary-font);
	font-size: 16px;
	font-weight: 400;
	font-style: normal;
	line-height: 26px;
	margin-bottom: 10px;
	color: var(--white);
}

.join {
	grid-area: join;
}

.join ul {
	display: flex;
	gap: 15px;
}

.join ul li svg{
	transition: all 0.2s ease-in;
}

.join ul li:hover svg {
	fill:  var(--third-logo-color);
}

.aboutUsLink {
	grid-area: aboutUsLink;
}

.useful {
	grid-area: useful;
}

.contact {
	grid-area: contact;
}

.copyBlock, 
.termsLink  {
	padding-top: 90px;
	color: var(--middle-blue);
	font-family: var(--primary-font);
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	line-height: 26px;
	grid-area: copyBlock;
}

.termsLink {
	display: flex;
	padding-top: 90px;
	grid-area: termsLink;
	gap: 5%;
}


.footer .termsLink a:hover {
	color: var(--white);
}

@media screen and (max-width: 935px){ 
	.footer .wrapper {
		grid-template-columns: 1fr ;
		max-width: var(--max-container-width);
		margin: 0 auto;
		grid-template-areas:
			"aboutUs"
			"aboutUsLink" 
			
			"useful"
			"contact"
			"join"
			
			"termsLink"
			"copyBlock";
	}

	.termsLink  {
		flex-direction: column;
		gap: 12px;
		padding-top: 40px;
	}

	.termsLink a, 
	.copyBlock {
		font-weight: normal;
	}

	.copyBlock {
		padding-top: 40px;
	}
}
