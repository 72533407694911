
.optionsContainer {
	margin-top: 60px;
}

.card {
	display: flex;
	padding: 10px 20px;
	justify-content: space-between;
	align-items: center;
	font-weight: 500;
}

.extraCSWrap .title {
	margin-bottom: 20px;
}

.bottom_buttons {
	display: flex;
	justify-content: space-between;
}

.wyswyg_editorWrap {
	margin-bottom: 40px;
}

.optionsList {
	margin-top: 40px;
	display: grid;
	gap: 30px	;
}

.optionsItem {
	border-radius: 15px;
	flex: 1;
	border: 2px solid var(--main-dark);
	padding: 30px;
	top: 0;
	transition: all 0.4s ease;
	cursor: pointer;
	position: relative;
	align-items: flex-start;
	background: var(--coral-second);
}



.optionsItem  li {
	font-size: 22px;
	font-style: normal;
	font-weight: 400;
	position: relative;
	line-height: 25px;
	margin-bottom: 12px;
	text-align: left;
}

.optionsItem  ul {
	list-style-type: disc;
	margin: 20px 0 20px 18px;
}

.accordionTitleWrap {
	width: 100%;
	cursor: pointer;
}

.content {
	text-align: left;
	margin-bottom: 20px;
}

.accordionTitle_top {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.accordionTitle_btn {
	display: flex;
	gap: 10px;
	align-items: center;
	font-size: 22px;
	font-style: normal;
	font-weight: 400;
}

.accordionTitle_btn svg {
	transform: rotate(90deg);
}

.accordionTitle {
	text-align: left;
	margin-top: 24px;
}

.accordionSection h5 {
	font-size: 25px;
	font-weight: 500;
}

.accordionTitle.active {
	border-bottom: 0;
}

.icons svg {
	z-index: 1;
	width: auto;
	height: 26px;
	position: relative;

}

.dropdown {
	overflow: hidden;
	height: 0;
	padding: 0;
	transition: all 0.2s ease;
	opacity: 0;
	font-size: 19px;
	font-weight: 400;
	line-height: 31px;
	margin-top: -15px;
}

.dropdown.show{
	overflow: visible;
	height: auto;
	margin-top: 0;
	opacity: 1;
}

.list p svg {
	margin-top: 3px;
}

.accordionTitleWrap.active .accordionTitle_btn   svg {
	transform: rotate(-90deg);
}

.list {
	margin-bottom: 15px;
}

.list p {
	display: grid;
	grid-template-columns: auto 1fr auto auto;
	gap: 8px;
	margin-bottom: 10px;
	align-items: start;
}



.icons {
	cursor: pointer;
	position: relative;
	display: block;
	text-align: center;
}



.icons svg path {
	transition: all 0.4s ease;
}

.icons:hover svg path {
	stroke: var(    --middle-blue)
}

.addNewopt {
	display: grid;
	gap: 20px;
	justify-items: center;
}


.button_group {
	display: flex;
	gap: 30px	;
	justify-items: center;
}

.editor {
	margin-bottom: 20px;
}

.wyswyg_editorWrap > div > div > div > div {
	position: relative!important;
	top: 0!important;
}

@media screen and (max-width: 768px) {
	.accordionTitle_top svg {
		max-height: 60px;
	}

	.cs_title {
		font-size: 25px;
	}

	.optionsItem h3 {
		font-size: 18px;
	}

	.content p,
	.optionsItem li {
		font-size: 16px;
		line-height: 22px;
	}
}