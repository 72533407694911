.mainStyleLogo {
	color: var(--main-dark);
	font-family: var(--primary-font);
	font-size: 30px;
	font-weight: 500;
	font-style: normal;
	line-height: 34px;
}

.logoSpan {
	color: var(--third-logo-color);
}

@media screen and (max-width: 935px) {
	.mainStyleLogo {
		font-size: 25px;
	}
}
@media screen and (max-width: 768px) {
	.mainStyleLogo {
		font-size: 25px;
	}
}