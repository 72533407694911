.modalWrap {
	display: grid;
	gap: 10px;
	justify-items: center;
	width: 100%;
}



.form {
	display: grid;
	max-width: 64%;
	width: 100%;
}

.form_askForSupport {
	margin-top: 50px;
}

.modalWrap p {
	text-align: center;
}

.modalWrap label {
	align-items: flex-start;
}

.modalWrap label span:first-child {
	margin-top: -5px;
}

.modalWrap label span {
	font-family: var(--primary-font);
}

.modalWrap p,
.modalWrap p a {
	font-size: 22px;
	line-height: 31px;
	font-weight: 400;
}

.formFieldCheckbox span {
	font-size: 20px;
}

.submit {
	margin-top: 10px;
	text-align: center;
	display: grid;
	justify-items: center;
}

.submit button {
	min-width: 200px;
}

.askForSupport {
	font-size: 22px;
	text-align: center;
}

.askForSupport  p{
	margin-bottom: 15px;
}

.askForSupport .formField {
	margin-bottom: 20px;
}

.askForSupport .formField input {
	min-height: 3em;
}

.select {
	width: 100%;
	margin-bottom: 20px;
}

.askForSupport  .select  div div {
	padding: 13px 22px;
}

.isItRightSuccessMsg  h4 {
	text-align: center;
	justify-content: space-around;
}

.isItRightSuccessMsg p {
	font-family: var(--primary-font);
	font-size: 22px;
	font-weight: 400;
	font-style: normal;
	line-height: 31px;
	margin-bottom: 15px;
}

.isItRightSuccessMsg p a {
	font-family: var(--primary-font);
	font-size: 22px;
	font-weight: 400;
	font-style: normal;
	line-height: 31px;
	margin-bottom: 15px;
	color: var(--middle-blue);
}

.askForSupport label span {
	font-size: 19px;
	font-family: var(--primary-font);
}

.askForSupport label span:first-child {
	padding: 4px;
} 

.askForSupport label {
	text-align: left;
	align-items: flex-start;
}

.form_askForSupport p {
	margin-bottom: 0;
}

@media screen and (max-width: 768px) {
	.modalWrap  img {
		height: 80px;
	}

	.form {
		max-width: 92%;
	}
}