
.divorceStepsWrap {
	background-color: var(--modal-gray);
	position: relative;
	box-shadow: 0 0 0 100vmax var(--modal-gray);
	clip-path: inset(0 -100vmax);
	padding: 42px 0;
}

.divorceSteps {
	display: flex;
	gap: 10px;
	justify-content: space-between;
}

.stepIntroText {
	display: flex;
	gap: 20px;
	font-size: 22px;
	text-align: left;
	line-height: 33px;
}

.stepIntroText img {
	width: 85px;
	height: 85px;
}

.text_left {
	align-items: flex-start;
	text-align: left;
}

.actionCards {
	padding: 35px;
}

.actionCards h3 {
	margin-bottom: 0;
}

@media screen and (max-width: 935px) {
	.dashboardHeader h1 {
		font-size: 50px;
	}
}

@media screen and (max-width: 768px) {
	.dashboardHeader {
		margin-bottom: 0;
	}

	.dashboardHeader h1 {
		font-size: 37px;
	}

	.divorceSteps { 
		flex-direction: column;
		margin-top: 0;
	}

	.stepIntroText img {
		display: none;
	}

	.divorceStepsWrap {
		padding: 20px 0;
	}

	.actionCards {
		padding: 20px;
		gap: 10px;
	}
}