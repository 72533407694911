.h1 {
	font-size: 48px;
	font-family: var(--secondary-font);
	font-weight: 400;
	line-height: 56px;
}

.h2 {
	font-family: var(--primary-font);
	font-size: 33px;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	
}

.h3 {
	font-family: var(--primary-font);
	font-size: 25px;
	font-weight: 500;
	font-style: normal;
	line-height: 32px;
}

.h4 {
	font-size: 19px;
	font-weight: 500;
	line-height: 22px;
	display: flex;
	align-items: center;
}


@media screen and (max-width: 1200px   ) {
	.homeHeader {
		font-size: 55px;
		font-weight: 500;
		font-style: normal;
		line-height: 56px;
	}

}

@media screen and (max-width: 1024px) {
	.homeHeader {
		font-size: 38px;
		font-weight: 500;
		font-style: normal;
		line-height: 38px;
	}

}
@media screen and (max-width: 960px) {
	.h1 {
		font-size: 32px;
		line-height: 50px;
	}

	.h2 {
		font-size: 25px;
		line-height: 30px;
	}

	.h3 {
		font-size: 22px;
		line-height: 27px;
	}

	.h4  {
		font-size: 20px;
		line-height: 25px;
	}

	.b1 {
		font-size: 18px;
	}

	.b2 {
		font-size: 12px;
		font-weight: 500;
	}
}