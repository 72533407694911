
.header_2 {
	display: grid;
	grid-template-columns: 1fr;
	gap: 20px;
	font-weight: 500;
	padding: 90px 50px;
	justify-items: end;
	max-width: calc(var(--max-container-width));
	background:  url("images/Karen_Massey.png") no-repeat 0 0; 
	background-size: auto 150%;
	margin: 0 auto;
}

.header_2_wrapper {
	max-width: 65%;
	display: grid;
	gap: 30px;
	justify-items: center;
}

.header_2_img_wrap {
	margin-top: 20px;
	display: grid;
	gap: 10px;
}

.header_2_img {
	width: 247px;
}

.header_2  p{
	font-size: 30px;
	font-weight: 500;
	line-height: 40px;
}

.header_2 svg {
	width: 126px;
	height: 45px;
}

.cardContainer {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 25px;
}

.buttonGroup {
	grid-column: 1/-1;
	margin-top: 10px;
	text-align: center;
}



.coral_bg {
	background-color: var(--first-logo-color);
}

.light_green_bg {
	background-color: var(--green-500);
}

.blue_bg {
	background-color: var(--second-logo-color);
}

.hiwWrap {
	margin-top: 80px;
	max-width: 100%;
	border-radius: 0;
}

.howItWork {
	position: relative;
	display: grid;
	max-width: var(--max-container-width);
	margin: 0 auto;
	text-align: center;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 20px 90px;
	justify-items: center;
	width: 100%;
}

.howItWork h2 {
	grid-column: 1/ -1;
	margin-bottom: 50px;
	color: var(--white)
}

.howItWork .s1 {
	position: relative;
}

.howItWork .s2 {
	position: relative;
}

.howItWork .circleStep {
	margin: 0 auto;
	color: var(--main-dark);
	position: relative;
	z-index: 1;
}

.howItWork .circleStep::before{
	content: '';
	z-index: 2;
}

.howItWork .hiwItem::after{
	position: absolute;
	z-index: 0;
	left: calc(50% + 45px);
	width: calc(100% + 40px);
	top: 37px;
	content: '';
	border-bottom: 22px solid var(--third-logo-color);
}

.howItWork .hiwItem.s3::after {
	display: none;
}

.howItWork .buttonGroup {
	position: relative;
	display: grid;
	gap: 20px;
	justify-items: center;
	margin-top: 58px;
}

.hiwItem p {
	color: var(--white);
	font-weight: 400;
	font-size: 19px;
}

.howItWork .buttonGroup button{
	padding: 20px 28px;
	font-size: 22px;
}

.howItWork .buttonGroup button:hover {
	background-color: var(--first-logo-color);
}

.howItWork .buttonGroup p {
	font-family: var(--secondary-font);
	color: var(--white);
	font-size: 18px;
}

.howItWork .buttonGroup p  a {
	color: var(--white);
	font-size: 18px;
}
		
.hiwItem {
	display: grid;
	font-size: 22px;
	font-weight: 500;
	line-height: 31px;
	gap: 30px;
	width: 100%;
}

.hiwItem_context p {
	margin-bottom: 5px;
}

.hiwItem a {
	font-family: var(--secondary-font);
	font-size: 18px;
	font-weight: 400;
	line-height: 28px;
	color: var(--white);
}

.hiwItem a:hover {
	text-decoration: none;
}

.roating_arrow {
	position: absolute;
	top: -31px;
	left: -141px;
}

.testimonials {
	gap: 30px;
	margin: auto;
	justify-items: center;
	padding-top: 0;
}

.testimonialHeader {
	display: grid;
	justify-items: center;
}

.testimonialHeader svg {
	width: 70px;
	height: 45px;
}

.testimonialsBlock {
	display: flex;
}

.team_section {
	display: grid;
	justify-items: center;
}

.team_img {
	margin-bottom: 34px;
	height: 45px;
}

.team_section h2 {
	font-size: 60px;
}

.team_section  .subtitle {
	margin-top: 10px;
	font-family: var(	--primary-font)
}

.team {
	display: flex;
	gap: 74px;
	justify-content: center;
	margin: 100px 0 69px;
}

.testimonialsList {
	column-count: 3;
	column-gap: 20px;
	width: 100%;
	margin-top: 40px;
}

.testimonialsList_item {
	margin-bottom: 20px;
	padding: 20px;
	text-align: left;
	-webkit-column-break-inside: avoid;
	page-break-inside: avoid;
	break-inside: avoid;
	gap: 0;
	background-color: var(--modal-gray);
}


.testimonialsList_content {
	margin: 10px 0;
}

.testimonialsList_item  svg path {
	fill: var(--third-logo-color)
}

.member {
	display: grid;
	gap: 10px;
	justify-items: center;
}

.member p {
	font-size: 19px;
	font-weight: 500;
}

.imgContainer {
	width: 160px;
	height: 160px;
	background-color: var(--first-logo-color);
	border-radius: 50%;
	margin-bottom: 10px;
	overflow: hidden;
}

.imgContainer img {
	max-width: 100%;
}


.faq {
	display: grid;
	justify-items: center;
}

.contactSection {
	margin: 100px auto;
	max-width: 1095px;
	padding: 70px 50px;
	display: grid;
	grid-template-columns: 35% 45%;
}

.contactSection h1,
.contactSection h2,
.contactSection h3,
.contactSection p {
	color: var(--white);
	margin-bottom: 0;
}

.leftPart {
	display: grid;
	gap: 37px;
}

.leftPart img {
	height: 86px;
}

.rightPart {
	display: grid;
	gap: 22px;
	justify-items: start;
}

.rightPart p {
	font-family: var(--secondary-font);
}

.section_services {
	display: grid;
	justify-items: center;
	text-align: center;
	gap: 20px;
}

.services {
	display: grid;
	gap: 50px 30px;
	grid-template-columns: repeat(6, minmax(135px, 1fr));
	text-align: left;
}

.services_item{
	overflow: hidden;
	grid-column: span 2;
}

.services_item:nth-child(1),
.services_item:nth-child(2) {
	grid-column: span 3
}

.services_item_img {
	width: 100%;
	overflow: hidden;
	margin-bottom: 24px;

}

.services_item_img img {
	max-width: 100%;
	height: auto; /* Keeps the aspect ratio */
	display: block;
	margin: -4px;
}

.services_item_content {
	display: grid;
	gap: 10px;
	margin-left: 5px;
}

.separated {
	border-bottom: 2px solid var(--main-dark);
	padding-bottom: 100px;
}

.newsletter_section {
	border-radius: 30px;
	background-color: var(--second-logo-color);
	display: grid;
	grid-template-columns: 1fr 2fr;
	gap: 100px;
	padding: 42px 48px;
	max-width: 1095px;
	margin: 50px auto;
}

.checkboxField {
	display: grid!important;
	grid-template-columns: auto 1fr;
	gap: 10px;
	align-items: start!important;
}

.checkboxField p {
	font-size: 15px;
	font-style: normal;
	font-weight: 500!important;
	line-height: 17px;
}

.checkboxField .checkbox {
	padding-top: 4px;
	align-items: flex-start;
}

.rightPart_btn:hover {
	background-color: var(--third-logo-color);
}

.click_way .click_way_header{
	text-align: center;
	margin-bottom: 40px;
}

.click_way_icon {
	margin: 0 auto;
	display: block;
	margin-bottom: 10px;
}

.click_way  li{
	display: grid;
	grid-template-columns: auto 1fr;
	gap: 0 30px;
	position: relative;
}

.click_way  li::before{
	content: '';
	width: 11px;
	height: 100%;
	position: absolute;
	background-color: var(--third-logo-color);
	left: 15px;
	z-index: 0;
	top: 35px;
}

.click_way li:last-child::before {
	display: none;
}

.click_way_step {
	width: 50px;
	height: 50px;
	font-size: 30px;
	font-style: normal;
	font-weight: 500;
	color: var(--white);
	z-index: 1;
}

.click_way li p {
	grid-column-start: 2;
	margin-bottom: 20px;
}

@media screen and (max-width:1024px) {
	
	.cardContainer {
		grid-template-columns: 1fr;
		gap: 30px;
	}

	.howItWork {
		grid-template-columns: 1fr;
		gap: 50px;
		text-align: left;
	}

	.hiwItem {
		width: 100%;
		text-align: left;
		grid-template-columns: 20% 1fr;
		grid-template-areas: 
			"circle hiwTitle"
			"circle hiwLink";
		gap: 15px 30px;
	}

	.circleStep {
		grid-area: circle;
	}

	.click_way  li::before{ 
		left: 12px;
		top: 20px;
	}

	.howItWork .hiwItem::after {
		top: 45px;
		left: 9.2%;
		width: 0;
		height: calc(150% + 20px);
		border: 0;
		border-left: 14px solid var(--third-logo-color);
	}

	.howItWork .s3 {
		align-items: end;
	}

	.htwTitle {
		grid-area: hiwTitle;
	}

	.hiwItem a{
		grid-area: hiwLink;
	}

	.howItWork .buttonGroup {
		font-size: 22px;
	}

	.howItWork .buttonGroup  p{
		padding: 0 40px;
	}

	.howItWork .buttonGroup  a,
	.howItWork .buttonGroup  p {
		font-size: 18px;
	}

	.howItWork .buttonGroup button{
		padding: 22px 20px;
		font-size: 22px;
	}

	.testimonials  {
		grid-template-columns: 1fr ;
		grid-template-areas: "title" 
			"testimonials";
		text-align: center;
	}

	.team {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	.team_section h2 {
		font-size: 37px;
	}

	.contactSection {
		grid-template-columns: auto 1fr;
		gap: 40px;
	}

	.contactSection .rightPart p {
		font-size: 18px;
	}

	.newsletter_section {
		margin: 30px auto;
		max-width: 95%;
	}

}

@media screen and (max-width: 990px){
	.header_2 { 
		padding: 30px 20px;
		background-color: #fff;
		padding-bottom: 50px;
		background-size: auto 80%;
		background-position: 0 100%;
		gap: 15px;
	}

	.header_2_wrapper {
		margin: 0 auto;
		max-width: 70%;
	}

	.services {
		grid-template-columns: repeat(6, 1fr);
	}

	.services_item	{
		grid-column: span 3!important;
	}
}

@media screen and (max-width: 768px){
	.howItWork h2 {
		margin-bottom: 0;
	}

	.header_2 p {
		font-size: 22px;
		line-height: 30px;
	}

	.team {
		display: grid;
	}

	.contactSection {
		grid-template-columns: 1fr;
	}


	.newsletter_section {
		grid-template-columns: 1fr;
		padding: 42px 28px;
		margin: 50px auto;
	}

	.testimonialsList {
		column-count: 1;
		column-gap: 20px;
	}

	.testimonialsList svg {
		display: none;
	}

	.click_way  li::before{
		left: 12px;
		top: 20px;
	}

	.member:nth-child(3),
	.member:nth-child(4) {
		display: none;
	}
}

@media screen and (max-width: 600px){
	.header_2 { 
		padding: 30px 20px;
		background-color: #fff;
		padding-bottom: 415px;
		background-size: auto 50%; 
		background-position: 0 100%;
		gap: 15px;
	}

	.header_2_wrapper {
		margin: 0 auto;
		max-width: 100%;
	}

	.team {
		grid-template-columns: 1fr;
		margin-top: 45px;
		gap: 45px;
	}

	.team_section {
		text-align: center;
		padding: 30px 20px;
	}

	.services {
		grid-template-columns: 1fr;
		gap: 30px;
	}

	.services_item {
		grid-column: span 1!important;
	}
}

.verified_error_modal a {
	font-size: 22px;
	font-weight: 400;
}

.verified_error_modal span {
	display: inline-block;
}