.sidebarTop {
	width: 100%;
	margin: 42px 0 20px -12px;
	display: grid!important;
	grid-template-columns: 1fr auto;
	padding-left: 15px;
	align-items: center;
	transition: all 0.2s ease;
}

.sidebar {
	overflow: hidden;
}

.sidebarWrap {
	transition: all 0.2s ease;
}

.sidebarWrap svg {
	cursor: pointer;
}

.sidebarWrap a + svg {
	margin-top: 4px ;
	
}

.sidebarWrap a + svg path {
	stroke: var(--second-logo-color)!important;
}

.sidebarWrap svg path {
	transition: color 0.2s ease;
}

.collapcedIcon svg circle, 
.collapcedIcon svg path{
	stroke: var(--third-logo-color)!important;
	stroke-width: 2.5;
}

.sidebarWrap svg:hover path{
	stroke: var(--white);
}

.sidebarTop h3 {
	margin-bottom: 0;
	
}

.sidebarTop a div {
	color: var(--white);
}

.sidebarCollapsed {
	transform: translateX(-80%);
	padding-right: 50px;
	height: 100%;
}

div:has(.sidebarCollapsed)  {
	grid-template-columns: 40px 1fr;
}

.sidebarCollapsed svg {
	margin-right: -10px;
	transform: rotate(180deg);
}

.collapsedItem {
	padding-left: 10px!important;
	color: var(--third-logo-color)!important;
	font-size: 20px;
	font-weight: 500;
	line-height: 23px;
	gap: 5px;
	display: grid!important;
	grid-template-columns: 1fr auto;
}

.collapsedItem  > div {
	flex: initial;
}

.sidebarItem {
	padding: 0!important;
	display: -webkit-box;
	display: flex;
	display: flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: relative;
	text-decoration: none;
	width: 100%;
	box-sizing: border-box;
	text-align: left;
}

.sidebarItem a {
	padding-left: 30px;
	font-size: 16px;
	line-height: 18px;
	transition: all 0.5s ease;
	color: #C9EBF2;
	padding-top: 10px;
	padding-bottom: 10px;
	font-weight: 500;
}

.sidebarItem a:hover {
	color: var(--third-logo-color);
}

a.active{
	border-radius: 10px;
	border: 1px solid #C9EBF2;
	padding: 10px;
	display: inline-block;
	margin-left: 10px;
	width: 100%;
	margin-right: 10px;
}

a.active:hover {
	background: #4EB072;
	color: var(--white);
}

.collapcedIcon svg {
	width: 21px;
}

.collapcedIcon  .down {
	transform: rotate(90deg);
}

.inviteEx {
	width: 67%;
	margin-top: 10px;
	margin-bottom: 20px;
	margin-left: 70px;
}

.mobileNav {
	display: none;
}
@media screen and (max-width: 1460px) {
	div:has(.sidebarCollapsed)  {
		grid-template-columns: 72px 1fr;
	}
}

@media screen and (max-width: 700px) {
	.sidebarCollapsedIconSvg {
		display: none;
	}

	.mobileNav {
		display: block;
	}

	.sidebar {
		display: none;
	}
}
