.instaWrap {
	text-align: center;
}

.feedList {
	display: grid;
	grid-template-columns:  1fr 1fr 1fr 1fr 1fr;
	margin-top: 40px;
}

.feedImg {
	max-width: 100%;
	display: block;
	backface-visibility: hidden;
	vertical-align: top;
	
}

.feedItem {
	position: relative;
	vertical-align: top;
	aspect-ratio: 1 / 1;
	overflow: hidden;

}

.videoFeed {
	width: 100%;
}

.feedItem:first-child{
	background-color: #CEF3E1;
} 

.feedItem:nth-child(2) {
	background-color: #D3E2FF;
} 

.feedItem:nth-child(3) {
	background-color: #FFDADA;
} 

.feedItem:nth-child(4) {
	background-color: #F5F6C7;
} 

.feedItem:nth-child(5) {
	background-color: #E1DCFD;
} 

.defaultImg {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 30%;
}

.feedItem a {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
	
}

.caption {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgb(0 0 0 / 60%);
	color: var(--white);
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding: 10px;
	opacity: 0;
	transition: all 0.4s ease;
}

.feedItem:hover .caption{
	opacity: 1;
}

.feedItem:hover img {
	zoom: 1;
}

.caption h5{
	margin: 0;
	font-weight: normal;
	opacity: 0;
	line-height: 26px;
	transition: all 0.4s ease;
	overflow: hidden;
	display: -webkit-box;
	color: var(--white);
	font-size: 22px;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.feedItem::before,
.feedItem::after {
	position: absolute;
	top: 20px;
	right: 20px;
	content: '';
	background-color: #FFF;
	z-index: 1;
	opacity: 0;
	box-sizing: border-box;
	transition: all 0.4s ease;
}

.feedItem::before {
	width: 0;
	height: 1px;
}

.feedItem::after {
	width: 1px;
	height: 0;
}

.feedItem:hover::before,

.feedItem:hover::after {
	opacity: 1;
	transition-delay: 0.25s;
}

.feedItem:hover::before {
	width: 40px;
}

.feedItem:hover::after {
	height: 40px;
}

.feedItem:hover h5 {
	transition-delay: 0.3s;
	opacity: 1;
}


@media screen  and (max-width: 780px){
	.feedList {
		grid-template-columns: 1fr;
		gap: 10px;
	}

	.feedItem:nth-child(4) ,
	.feedItem:nth-child(5) {
		display: none;
	} 
}