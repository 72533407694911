.widgetBlock {
	margin-top: 30px;
	margin-bottom: 40px;
}

.csForm {
	background-color: #fff;
	border-radius: 10px;
	padding: 20px;
	margin-top: 30px;
}

.csForm .title {
	margin-bottom: 20px;
}

.formField {
	display: grid;
	gap: 20px;
	grid-template-columns: auto 1fr;
	margin-bottom: 20px;
}

.subtitle {
	margin-top: 20px;
	margin-bottom: 50px;
}

.subtitle .label   {
	padding-left: 0!important;
	color: var(--main-dark)!important;
	font-weight: 500!important;
	font-family: var(--primary-font);
	font-size: 22px!important;
}

.csForm  .subtitleGroup {
	display: grid;
	grid-template-columns: 1fr  ;
	margin-bottom: 15px;
}

.csForm  .subtitleField {
	display: grid;
	grid-template-columns: 1fr 1fr  1fr;
}

.wyswyg_editorWrap {
	margin-top: 20px;
	margin-bottom: 40px;
}

.options {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	margin-top: 20px;
	gap: 20px;
}

.preview {
	margin-bottom: 20px;
	display: block;
}

.ecs_list {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 30px;
	margin-top: 20px;
}