.guideList {
	display: grid;
	grid-template-columns: repeat( 4, 1fr);
	gap: 21px;
	margin-top: 10px!important;
}

.listItem {
	margin: 0!important;
	padding: 0!important;
}

.guide_card {
	background: var(--modal-gray);
	width: 100%;
	height: 100%;
	padding: 0;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr;
	overflow: hidden;
	justify-items: center;
	position: relative;
	transition: all 0.4s ease-in;
	top: 0;
	gap: 15px;
}

.guide_card:hover {
	cursor: pointer;
	box-shadow: 0 3px 5px 1px var(--shadow);
	top: -10px;
}

.guide_preview{
	background: var(--light-blue);
	aspect-ratio: 3.6 / 2;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;
}

.guide_preview img {
	max-width: 100%;
	min-height: 100%;
}

.guide_meta {
	display: grid;
	justify-items: center;
	justify-items: start;
	padding: 0 10px 10px;
	grid-template-rows: 1fr 47px;
	width: 100%;
	text-align: left;
	line-height: 23px;
	gap: 10px;
}


.guide_meta_extra {
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
}

.guide_meta_extra .chip {
	font-size: 12px;
	font-family: var( --primary-font);
	color: var(--main-dark);
	border-color: var(--main-dark)
}

.guide_meta_readingTime {
	display: flex;
	align-items: center;
	gap: 5px;
	font-size: 12px;
}

.filterWrap {
	display: grid;
	grid-template-columns: 1fr auto;
	gap: 16px;
	align-items: start;
	margin-top: 10px;
}

.filterWrap span {
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	cursor: pointer;
	font-family: var(--primary-font);
}

.tagsList {
	display: flex;
	gap: 5px;
	flex-wrap: wrap;
}

.tag {
	border-color: var(--main-dark)!important;
	cursor: pointer;
}

.activeTag {
	background-color: var(--light-blue)!important;
}

.tag:hover {
	background: var(--light-blue)!important;
}

.template_preview {
	background: var(--light-blue);
	aspect-ratio: 3.6 / 2;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;
}

.template_preview.agenda {
	background-color: var(--wellbeing-bg);
}

.template_preview.legal {
	background-color: var(--light-blue);
}

.template_preview.letter {
	background-color: #FEF8F7;
}

.template_preview.mail {
	background-color: var(--coral-second);
}

.template_preview.script {
	background-color: #B8CCF4;
}

.template_preview.spreadsheet {
	background-color: var(--green-500);
}

.template_preview img {
	max-width: 100%;
}

.template_preview svg {
	height: 100%;
}

@media screen and (max-width: 1124px) {
	.guideList {
		grid-template-columns: repeat(4, 1fr)!important;
	}

	.guide_card  {
		grid-template-rows: auto 1fr ;
	}	
}

@media screen and (max-width: 935px) {
	.guideList {
		grid-template-columns: repeat(4, 1fr)!important;
	}
}

@media screen and (max-width: 768px) {
	.listWrap {
		overflow-x: scroll;
	}

	.listWrap::-webkit-scrollbar {
		display: none;
		width: 0;
		height: 0;
		background: transparent;
	}

	.listItem{
		flex-basis: 25%;
		min-width: 135px;
	}

	.guide_card  {
		grid-template-rows: auto 1fr ;
		gap: 10px;
	}

	.guideList {
		display: flex;
		flex-wrap: nowrap;
		gap: 10px;
	}

	.guide_meta {
		grid-template-rows: 1fr;
	}

	.guide_meta_extra .chip,
	.guide_meta_readingTime {
		font-size: 12px;
	}

	.guide_meta_extra {
		display: none;
	}

	.filterWrap {
		grid-template-columns: 1fr;
	}

	.filterWrap span {
		font-size: 12px;
	}

	.filterWrap > span {
		display: none;
	}

	.filterWrap .showBtn,
	.filterWrap .hideBtn {
		justify-self: flex-start;
		margin-bottom: 20px;
	}


	.tagsList {
		flex-wrap: nowrap;
	}
	
}