.coaching_section {
	display: grid;
	gap: 10px;
	margin-top: 0!important;
	padding-top: 0!important;

}

.coachImg {
	width: 100%;
	display: grid;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.coachImg  img {
	width: 100%;
}

.coaching_section figure {
	margin-top: 20px;
	margin-bottom: 20px;
}

.coaching_section  h3 {
	margin-top: 20px;
}

@media screen and (max-width: 768px) {
	.coaching_section {
		padding: 0!important;
	}
}