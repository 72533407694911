
.uploadFileBtn {
	background-color: var(--text-highlight);
	border: 1px solid #DDD;
	padding: 10px 20px;
	display: inline-block;
	border-radius: 5px;
	cursor: pointer;
	font-weight: 500;
	color: var(--white);
	transition: all 0.5s ease;
	margin-right: 10px;
}

.selectType {
	display: grid;
	grid-template-columns: auto 1fr;
	gap: 30px;
	margin: 10px 0;
}

.fileName {
	margin-bottom: 20px;
}

.uploadFileBtn:hover {
	background-color: var(--middle-blue);
} 

.attachList p{
	font-size: 19px;
	text-transform: lowercase;
	display: flex;
	gap: 10px;
	align-items: center;

}

.attachList p svg {
	width: 18px;
	height: 18px;
	cursor: pointer;
}
