

.rdrMonthAndYearWrapper{
	position: absolute;
	width: 290px;
	background-color: #fff;
	top: 60px;
	padding-top: 0;
	z-index: 3;
	box-shadow: 0 0 5px #eee;
}

.rdrMonthsVertical {
	position: absolute;
	width: 290px;
	background-color: #fff;
	top: 120px;
	box-shadow: 0 0 5px #eee;
	z-index: 2;
}

.rdrMonth {
	width: 290px;
}

