.formContainer {
	background-color: #ffffff;
	padding: 20px 10px;
	border-radius: 10px;
}

.selects {
	margin-bottom: 30px;
	width: 50%;
}

.selects div {
	min-height: 1em!important;
	line-height: 1em!important;
	padding-left: 0;
}

.selects label {
	padding: 2px!important;
	color: var(--light-grey)!important;
	font-weight: 500!important;
	line-height: 22px;
	gap: 10px;
	font-family: var(--primary-font);
	background-color: #fff;
}

.tags {
	width: 50%;
}

.formField {
	display: grid;
	gap: 20px;
	grid-template-columns: auto 1fr;
	margin-bottom: 20px;
}
