.accountContainer p,
.accountContainer a,
.accountContainer p a, .accountContainer div {
	font-weight: 400;
}

.pageTitle {
	grid-column: 1 /-1;
	margin-bottom: 20px;
}

.accountContainer .formCard {
	background-color: transparent;
	box-shadow: none;
	border: 2px solid var(--main-dark);
	border-radius: 15px;
}

.accountContainer .tabLabel {
	font-size: 17px;
	font-weight: 500;
	text-transform: capitalize;
	color: var(--white);
	transition: color 0.4s ease;

}

.accountContainer .tabLabel:hover {
	color: var(--third-logo-color);
}


.accountContainer .formCard.userPhotoCard {
	background: var(--first-logo-color);
	display: grid;
}

input:disabled {
	border: 0;
	padding: 0;
	font-weight: 500;
	font-size: 22px;
	line-height: 31px;
	font-family: var(--primary-font);
}

.accountContainer .tabsLabes {
	background: var(--main-dark);
}

.form {
	display: grid;
	gap: 20px;
	margin-bottom: 60px;
}

.form:last-child{
	margin-bottom: 0;
}

.label {
	font-weight: 500;
	font-size: 17px;
	line-height: 25px;
	color: var(--light-grey);
	font-family: var(--primary-font);
}

.buttonGroup {
	display: flex;
	justify-content: space-between;
}

.form .ageGroups {
	display: flex;
	flex-direction: row;
}

.editBtn {
	display: inline-block;
	box-sizing: border-box;
	padding: 8px 25px;
	cursor: pointer;
	transition: all 0.2s;
	text-align: center;
	letter-spacing: 0.01em;
	color: var(--main-dark);
	border: 2px solid var(--main-dark);
	border-radius: 10px;
	font-family: 'GT Walsheim', Arial, sans-serif;
	font-size: 18px;
	font-weight: 500;
	line-height: 21px;
	gap: 10px;
	background-color: var(--third-logo-color);
}

.editBtn:hover {
	color: var(--modal-gray);
	background-color: var(--main-dark);
}

.form .inline {
	max-width: max-content;
	display: inline;
}

.form .formControl {
	font-weight: 500;
	font-size: 18px;
	line-height: 31px;
	font-family: var(--primary-font);
}

.form .formControl span,
.form .formControl a {
	font-size: 18px;
}

.formControl li {
	margin-top: 10px;
}

.accauntWrap {
	display: grid;
	gap: 20px;
}

.labelFalse {
	position: relative;
	color: #89A2B0;
	text-decoration: line-through
}

.refundBlock {
	border-top: 1px solid var(--main-dark);
	border-bottom: 1px solid var(--main-dark);
	padding-top: 20px;
	padding-bottom: 20px;
}

.form .formControl span {
	font-size: 18px;
}

.notificationMsg {
	margin-bottom: 20px;
}

.notificationMsg a {
	font-size: 22px;;
}

.notificationMsg p {
	font-weight: 500;
}

.optOut hr {
	margin-bottom: 10px;
}

.becomeMember {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 10px;
	position: absolute;
	top: 20px;
	z-index: 99;
	right: 0;
}

.label {
	position: relative;
}

.visibilityIcon {
	position: absolute;
	bottom: 5px;
	right: 7px;
	transition: all 0.4s ease;
}

.visibilityIcon:hover {
	cursor: pointer;
	color: var(--middle-blue);
}


@media screen and (max-width: 768px) {
	.accountContainer .tabLabel {
		padding: 12px 8px!important;
		min-width: 50px!important; 
	}

	.tabsLabes > div:first-child {
		display: none;
	}


	.tabsLabes > div:first-child {
		display: none;
	}
}