.stepperWrap {
	margin-top: 40px;
	display: grid;
	gap: 10px;
}

.stepperWrap p {
	font-weight: 500;
	font-size: 15px;
	line-height: 25px;
	color: var(--light-grey);
}

.stepper {
	display: flex;
	gap: 5px;
}

.stepIndicator {
	display: inline-block;
	width: 100%;
	height: 9px;
	opacity: 0.7;
	border-radius: 3px;
	background-color: var(--green-500) ;
}

.stepIndicator.active {
	opacity: 1;
	background-color: var(--third-logo-color);
}