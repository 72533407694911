.logo_slider_container {
	overflow: hidden;
	width: 100%;
	white-space: nowrap;
	position: relative;
	padding:36px 0;
	margin-bottom: 50px;
}

.logos_scroll {
	display: flex;
	align-items: center;
	width: 100svw;
	animation: scroll-marquee linear infinite;
}

@keyframes scroll-marquee {
	from {
		transform: translateX(0);
	}
	
	to {
		transform: translateX(-50%);
	}
}

.logos_scroll > div {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 10px;
}

.logos_scroll img {
	flex-shrink: 0;
	max-height: 80px; 
	max-width: 100%;
}

@media screen and (max-width: 990px) {
	.logo_slider_container {
		margin-bottom: 0;
	}
	
}

@media screen and (max-width: 768px) {
	.logo_slider_container {
		margin-bottom: 0;
	}
}
