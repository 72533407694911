
body {
	margin: 0;
	background-color: var(--dashboard-bg);
	font-family: 'GT Walsheim', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: var(--main-dark);
	
}


* {
	box-sizing: border-box;
}

ul,
ol {
	margin: 0;
	padding: 0;
	list-style: none;
}

p,
h1,
h2,
h3,
h4,
li,
input,
h5 {
	margin: 0;
	padding: 0;
	color: var(--main-dark);
}

p,
p a {
	font-size: 18px;
	line-height: 25px;
}

input::-ms-reveal,
input::-ms-clear {
	display: none;
}

a {
	color: var(--main-dark);
	font-size: 18px;
	font-weight: 500;
	line-height: 20px;
	text-decoration: none;
}

@font-face {
	font-family: 'Canela Deck';
	src: url('./fonts/Canela-Regular-Web.woff2') format('woff2'),
		url('./fonts/Canela-Regular-Web.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'GT Walsheim';
	src: url('./fonts/gt-walsheim-regular-webfont.eot');
	src: url('./fonts/gt-walsheim-regular-webfont.eot?#iefix') format('embedded-opentype'),
		url('./fonts/GT-Walsheim-Regular.woff2') format('woff2'),
		url('./fonts/GT-Walsheim-Regular.woff') format('woff'),
		url('./fonts/GT-Walsheim-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'GT Walsheim';
	src: url('./fonts/gt-walsheim-medium-webfont.eot');
	src: url('./fonts/gt-walsheim-medium-webfont.eot?#iefix') format('embedded-opentype'),
		url('./fonts/GT-Walsheim-Medium.woff2') format('woff2'),
		url('./fonts/GT-Walsheim-Medium.ttf') format('truetype'),
		url('./fonts/GT-Walsheim-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;

}

@font-face {
	font-family: 'GT Walsheim';
	src: url('./fonts/gt-walsheim-medium-webfont.eot');
	src: url('./fonts/gt-walsheim-medium-webfont.eot?#iefix') format('embedded-opentype'),
		url('./fonts/GT-Walsheim-Medium.woff2') format('woff2'),
		url('./fonts/GT-Walsheim-Medium.ttf') format('truetype'),
		url('./fonts/GT-Walsheim-Medium.woff') format('woff');
	font-weight: 300;
	font-style: normal;

}

:root {
	--main-dark: #102D3E;
	--dashboard-bg: #F7F2FA;
	--blue-bg: #6BC2D6;
	--first-logo-color: #FAB39D;
	--second-logo-color: #91CCD1;
	--third-logo-color: #4EB072;
	--text-highlight: #43ADB6;
	--middle-blue: #0999B9;
	--dark-green: #2D894F;
	--light-blue: #C9EBF2;
	--lime: #D0ECE7;
	--green-500: #CEEED7;
	--white: #F7ECE8;
	--coral: #E69177;
	--coral-second: #FFD9CD;
	--red: #E34919;
	--error: #E34919;
	--light-grey: #89A2B0;
	--primary-font: 'GT Walsheim', 'Arial', sans-serif;
	--light-broun: #DEAD7F;
	--secondary-font: 'Canela Deck';
	--max-container-width: 1315px;
	--modal-gray: #ECE6F0;
	--input-bg: #E4D7D2;
	--wellbeing-bg: #FFB097;
	--shadow: #A6A5A5;
}

.section {
	max-width: calc(var(--max-container-width) - 3%);
	margin: auto;
	padding: 60px 15px;
}

.centred {
	text-align: center;
}

.darkBg {
	max-width: calc(var(--max-container-width) - 5%);
	padding: 140px 120px;
	color: var(--white);
	border-radius: 30px;
	background: var(--main-dark);
	z-index: 0;
}

.darkBg h2 {
	color: var(--white);
}

.innerPageBlock {
	display: grid;
	margin:0 auto 120px;
	font-family: var(--primary-font);
	font-size: 30px;
	font-weight: 500;
	font-style: normal;
	line-height: 40px;
	grid-template-columns: 250px 1fr;
	gap: 100px;
}



.innerPageBlock p {
	margin-bottom: 20px;
}

.black_link {
	color: var(--main-dark)!important;
}

.green_color {
	color: var(--third-logo-color)!important;
}

.blue_color {
	color: var(--middle-blue)!important;
}


.MuiFormControl-root .MuiOutlinedInput-root {
	border: 2px solid var(--main-dark);
	border-radius: 10px;
}

.MuiInputBase-formControl .MuiOutlinedInput-input{
	padding: 13px 18px 8px;
	border: 0;
	border-radius: 0;
	font-size: 19px;
	font-weight: 400;
	line-height: 22px;
	font-family: var(--primary-font)!important;
}

.MuiFormControl-root .MuiInputLabel-root {
	padding: 7px 22px;
	color: var(--light-grey);
	font-size: 19px;
	font-weight: 400;
	line-height: 22px;
	top: -9px;
	left: -20px;
}

.MuiInputBase-formControl .MuiOutlinedInput-notchedOutline {
	border: 0;
	border-radius: 0;
}

.MuiPopover-root .MuiPopover-paper {
	margin: 0;
	padding: 0;
	border-radius: 10px;
	box-shadow: 0 0 5px 0 var(--main-dark);
	background-color: var( --modal-gray);
}

.MuiPopover-root .MuiPopover-paper ul{
	padding: 0;
}

.MuiPopover-root .MuiPopover-paper ul li {
	padding: 10px;
	border-bottom: 1px solid var(--main-dark);
	background-color: var( --modal-gray)!important;
	font-size: 17px;
	display: flex;
	gap: 10px;
	font-weight: 500;
	line-height: 25px;
}

.MuiPopover-root .MuiPopover-paper ul li  span {
	padding: 0;
	
}

.MuiPopover-root .MuiPopover-paper:has( .nestedItem) {
	min-width: 160px;
	border: 0;
}


.MuiPopover-root .MuiPopover-paper .nestedItem {
	border: 0;
	font-size: 14px!important;
	cursor: pointer;
	line-height: 25px!important;
	padding: 5px 15px!important;
}

.MuiPopover-root .MuiPopover-paper ul li.Mui-selected,
.MuiPopover-root .MuiPopover-paper ul li:hover {
	background-color: var(--input-bg)!important;
}

.MuiPopover-root .MuiPopover-paper ul li:last-child{
	border-bottom: 0;
}

.MuiSelect-iconOutlined  {
	right: 30px;
	z-index: 7;
	top: 9px!important;
}

.error {
	margin: 3px 0 6px;
	text-align: left;
	letter-spacing: 0.0333em;
	color: var(--error);
	font-family: var(--primary-font);
	font-size: 0.75rem!important;
	font-weight: 400!important;
	line-height: 1.66!important;
}

.success {
	color: var(--third-logo-color);
	font-size: 20px;
}

.success::first-letter {
	text-transform: capitalize;
}

.hover-underline-animation {
	position: relative;
	cursor: pointer;
}

.hover-underline-animation::after {
	content: '';
	position: absolute;
	width: 100%;
	transform: scaleX(0);
	height: 1px;
	bottom: 0;
	left: 0;
	background-color: var(--main-dark);
	transform-origin: bottom right;
	transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
	transform: scaleX(1);
	transform-origin: bottom left;
}

.hover-underline-animation_back {
	position: relative;
	cursor: pointer;
}

.hover-underline-animation_back::after {
	content: '';
	position: absolute;
	width: 100%;
	height: 1px;
	bottom: 0;
	left: 0;
	background-color: var(--main-dark);
	transition: transform 0.25s ease-out;
	transform: scaleX(1);
	transform-origin: bottom left;
}

.hover-underline-animation_back.white::after{
	background-color: var(--white);
}

.hover-underline-animation_back:hover::after {
	transform: scaleX(0);
	transform-origin: bottom right;
}


input[type='text'],
input[type='number'],
input[type='password']  {
	display: flex;
	align-items: center;
	flex-direction: row;
	width: 278px;
	width: 100%;
	padding: 8px 15px;
	color: var(--main-dark);
	border: 2px solid var(--main-dark);
	border-radius: 10px;  
	background: transparent;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	gap: 10px;
	font-family: var(--primary-font);
}

input[type='text']::placeholder,
input[type='number']::placeholder,
input[type='password']::placeholder{
	color: var(--light-grey);
    
}

input:focus {
	outline: none;
	appearance: none;
}

.rdw-center-aligned-block > div {
	text-align: center;
}

.rdw-right-aligned-block > div {
	text-align: right;
}

.rdw-justify-aligned-block > div {
	text-align: justify;
}

.disabledFormControl span:has(input:checked) + .MuiFormControlLabel-label.Mui-disabled {
	color: var(--main-dark)!important;
	font-weight: 500;
	font-family: var(--primary-font);
}

.disabledFormControl input:checked + span svg {
	fill: var(--main-dark);
}

.unauthorized {
	display: grid;
	align-content: center;
	justify-content: center;
	align-items: center;
	min-height: 100svh;

}

.unauthorized h1 {
	font-size: 50px;
}

.unauthorized span,
.unauthorized span a {
	font-size: 30px;
}

.unauthorized span a {
	text-decoration: underline;
}

.MuiModal-backdrop {
	background-color: rgb(0 0 0 / 80%)!important;
	max-width: 100vw;
}

.MuiModal-root {
	max-width: 100vw;
}

.customMenuPopover .MuiModal-backdrop {
	background-color: transparent!important;
}

.se-image-container.__se__float-.__se__float-left{
	float: left;
	margin-right: 15px;
	margin-bottom: 10px;
}

.se-image-container.__se__float-.__se__float-right{
	float: right;
	margin-left: 15px;
	margin-bottom: 10px;
}

.MuiCheckbox-root svg,
.MuiRadio-root svg{
	display: none;
}

.MuiRadio-root input[type="radio"] + span {
	width: 18px;
	height: 18px;
	border-radius: 50%;
	position: relative;
	border: 2px solid var(--main-dark);
}

.MuiCheckbox-root input[type="checkbox"] ~ span {
	width: 18px;
	height: 18px;
	border-radius: 5px;
	position: relative;
	border: 2px solid var(--main-dark);
}

.MuiRadio-root input[type="radio"]:checked + span::before {
	width: 8px;
	height: 8px;
	content: '';
	position: absolute;
	top: 3px;
	left: 3px;
	background-color:  var(--main-dark);
	border-radius: 5px;
}

.MuiCheckbox-root input[type="checkbox"]:checked ~ span::before {
	width: 8px;
	height: 8px;
	content: '';
	position: absolute;
	top: 3px;
	left: 3px;
	border-radius: 5px;
	background-image:  url('../components/Icons/images/DefaultCheck.svg');
	filter: grayscale(100%) sepia(100%) brightness(200%) saturate(0%) contrast(1000%) invert(100%);

}

.css-l4u8b9-MuiInputBase-root-MuiInput-root::before {
	border-bottom: 0!important;
}

.sun-editor .se-toolbar-sticky-dummy {
	display: none!important;
}

.coral_bg {
	background-color: var(--first-logo-color);
}

.green_bg {
	background-color: var(--third-logo-color);
}

.orange_bg {
	background-color: var(--light-broun);
}

.blue_bg {
	background-color: var(--blue-bg);
}

.wellbeing_bg {
	background-color: var(--wellbeing-bg);
}

.mobile_hide {
	display: none;
}

@media screen and (max-width: 935px){
	.section {
		overflow: hidden;
		padding: 40px 25px;
	}

	.darkBg {
		width: 100%;
		padding: 60px 25px;
		border-radius: 0;
	}

	.mobile_hide {
		display: none;
	}

	.innerPageBlock {
		margin-bottom: 0;
		grid-template-areas:
			"contentBlock"
			"imgBlock";
		grid-template-columns: 1fr;
		gap: 30px;
		font-size: 25px;
		line-height: 40px;
	}

	.contentBlock {
		grid-area: contentBlock;
	}

	.contentBlock h2 {
		margin-bottom: 15px;
	}

	.imgBlock {
		grid-area: imgBlock;
		text-align: center;
	}

	.innerPageBlock svg {
		max-width: 140px;
		max-height: 100px;
	}
}

@media screen and (max-width: 768px){
	.section {
		padding: 30px 25px;
	}

	.mobile_hide {
		display: block;
	}
}

.MuiDataGrid-filterFormValueInput:last-child  {
	position: static!important;
}


.image_container {
	padding: 0;
	margin: 0;
	overflow: hidden;
}

.image_container img {
	max-width: 100%;
}

.ul, .ol {
	list-style: disc;
	margin: 0;
	margin-top: 5px;
}

.ol {
	list-style: decimal;
}

.ul li,
.ol li {
	margin-left: 18px;
	margin-bottom: 10px;
	font-size: 18px;
}

.pre {
	font-family: var(--primary-font);
	font-size: 18px;
	margin: 0;
	white-space: pre-wrap;
}

input[type='password']:focus,
input:-internal-autofill-selected  {
	outline: none;
	background-color: transparent!important;

}

.inputField {
	display: flex;
	align-items: center;
	flex-direction: row;
	color: var(--main-dark);
	border: 2px solid var(--main-dark);
	border-radius: 10px;
	background: transparent;
	font-size: 19px;
	font-weight: 500;
	line-height: 42px;
	gap: 10px;
}

.inputField-thin {
	height: 42px;
	padding: 10px 15px;
}

.mb10{
	margin-bottom: 10px;
}

.mb15{
	margin-bottom: 15px;
}

.mb25{
	margin-bottom: 25px;
}

.mb32 {
	margin-bottom: 32px;
}

.mb45 {
	margin-bottom: 45px;
}

.p26{
	padding: 26px;
}

.b1 {
	font-weight: 400;
	font-size: 18px;
	line-height: 25px;
}

.b2 {
	font-weight: 400;
	font-size: 15px;
	line-height: 21px;
}

@media screen and (max-width: 768px) {
	.mb45 {
		margin-bottom: 25px;
	}

	.mb32 {
		margin-bottom: 22px;
	}

	.b1, p {
		font-size: 18px;
		line-height: 23px;
	}

	.b2 {
		font-size: 12px;
		line-height: 18px;
	}
}