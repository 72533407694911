.boardWrap {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 24px;
	align-items: start;
	position: relative;
	padding-bottom: 0;
}

.tasksColumn {
	display: grid;
	grid-template-columns: 1fr;
	gap: 12px;
	background-color: var(--modal-gray);
	border-radius: 10px;
	overflow: hidden;
}

.boardTitle {
	display: block;
	align-items: center;
	font-size: 24px;
	margin: 0;
	justify-content: space-between;
	padding: 16px 19px 12px;
	background-color: var(--second-logo-color);
}


.boardTitle::first-letter {
	text-transform: uppercase;
} 

.boardTitle svg {
	width: auto;
	height: 24px;
	margin-left: 10px;
}

.boardWrap h2 {
	grid-column: 1 / -1;
	font-size: 40px;
	display: flex;
	align-items: center;
	gap: 10px;
}

.boardWrap h2 svg {
	width: 24px;
	height: 24px;
}

.tasks_content {
	padding: 0 20px 5px;
}

@media screen and (max-width: 1460px) {
	.boardWrap {
		gap: 20px
	}

	.boardTitle {
		font-size: 25px;
	}


	.boardWrap h2 {
		font-size: 40px;
	}
}

@media screen and (max-width: 935px) {
	.boardWrap h2 {
		font-size: 32px;
	}

	.tasksColumn > div {
		max-width: 100%;
	}
}

@media screen and (max-width: 768px) {
	.boardWrap {
		grid-template-columns: 1fr;
	}
}