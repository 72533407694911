
.savedGuidesWrap {
	margin: 0 auto;
}

.saveBookmarksHeader p {
	font-weight: 500;
	margin-top: 26px;
}

.article {
	margin-top: -100px;
}

.article h3 {
	display: flex;
	align-items: center;
}

.article .addToBook {
	font-size: 40px!important;
	margin-left: 15px;
}

.addToBook:hover {
	cursor: pointer;
	opacity: 0.7;
}

@media screen and (max-width: 768px) {
	.bookmarks_block {
		overflow: hidden;
		margin-top: 30px;
	}
}