.csWrap {
	margin: 0 auto;
}

.cs_list {
	display: grid;
	margin: 0;
	gap: 20px;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	margin-top: 20px!important;
}

.cs_card {
	background: var(--modal-gray);
	width: 100%;
	height: 100%;
	padding: 0;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr;
	overflow: hidden;
	justify-items: center;
	position: relative;
	transition: all 0.4s ease-in;
	top: 0;
	gap: 17px;
}

.cs_card:hover {
	cursor: pointer;
	box-shadow: 0 3px 5px 1px var(--shadow);
	top: -10px;
}

.guide_meta {
	display: grid;
	justify-items: center;
	justify-items: start;
	padding: 0 10px 10px;
	grid-template-rows: 1fr 47px;
	width: 100%;
	text-align: left;
	gap: 10px;
}

.guide_meta_extra {
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
}

.guide_meta_extra .chip {
	font-size: 14px;
	font-family: var( --primary-font);
	color: var(--main-dark);
	border-color: var(--main-dark)
}

.guide_meta_readingTime {
	display: flex;
	align-items: center;
	gap: 5px;
	font-size: 14px;
}

.guide_preview{
	background: var(--light-blue);
	aspect-ratio: 3.6 / 2;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;
}

.guide_preview img {
	max-width: 100%;
}

.thumb {
	margin-bottom: 45px;
	max-height: 600px;
	overflow: hidden;
}

.thumb img{
	width: 100%;	
}

.listItem {
	margin: 0!important;
	padding: 0!important;
}

.subtitle {
	display: inline-block;
	margin: 0 0 30px;
}

.subtitle::first-letter {
	text-transform: uppercase;
} 

.csArticle {
	margin-top: 50px;
	margin-bottom: 50px;
}

.csArticle ul li::before {
	filter: brightness(0) saturate(100%) invert(69%) sepia(73%) saturate(7148%) hue-rotate(164deg) brightness(97%) contrast(93%);
}


.cons_title,
.pros_title {
	color: var(--first-logo-color);
	font-size: 19px;
	font-style: normal;
	font-weight: 500;
	line-height: 25px;
	margin: 20px 0;
}

.pros_title {
	color: var(--third-logo-color);
}

.cons_list,
.pros_list {
	margin-left: 50px;
}

.cons_list li,
.pros_list li {
	font-size: 19px;
	font-style: normal;
	font-weight: 500;
	line-height: 25px;
	color: var(--main-dark);
	display: flex;
	gap: 8px;
	margin-bottom: 20px;
}

.cons_list  .cons_icon {
	color: var(--first-logo-color);
	margin-top: -4px;
	width: 30px;
	height: 30px;
}

.pros_icon {
	height: 30px;
	margin-top: -4px;
}

.pros_icon path,
.pros_icon circle {
	stroke: var(--third-logo-color);
}

.csList {
	width: 100%;
	margin: 0 auto;
}

.csList h2 {
	margin-left: 20px;
	margin-right: 20px;
	padding-bottom: 20px;
	margin-bottom: 20px;
	border-bottom: 2px solid var(--main-dark);
}

.listItem a,
.optItem {
	font-weight: 400;
	font-size: 19px;
	line-height: 25px;
	text-align-last: left;
	padding: 25px 30px;
	display: inline-block;
	background: var(--coral-second);
	width: 100%;
	border: 2px solid var(--main-dark);
	border-radius: 15px;
	transition: all 0.2s ease;
}

.listItem a:hover {
	background-color: transparent;
}

.otherOptions {
	margin-top: 50px;
}

.optItem {
	margin-bottom: 20px;
}

.otherOpt_title {
	display: flex;
	align-items: center;
	font-size: 22px;
	font-style: normal;
	font-weight: 400;
	line-height: 31px;
	gap: 10px;
}

.optItem ul {
	margin-top: 20px;
}

.optItem li {
	margin-bottom: 10px;
	font-size: 19px;
	font-style: normal;
	font-weight: 500;
	line-height: 25px;
	position: relative;
	display: flex;
	gap: 10px;
}

.optItem li::before{
	content: '\2022';	
}

.backBtn {
	cursor: pointer;
}

@media screen and (max-width: 1124px) {
	.cs_list {
		grid-template-columns: repeat(4, 1fr)!important;
	}

	.cs_card  {
		grid-template-rows: auto 1fr ;
	}
}
@media screen and (max-width: 870px) {
	.cs_list {
		grid-template-columns: repeat(4, 1fr)!important;
	}
}

@media screen and (max-width: 750px) {
	.cs_list {
		grid-template-columns: repeat(1, 1fr)!important;
	}

	.cs_card  {
		grid-template-rows: auto 1fr ;
	}
}

@media screen and (max-width: 768px) {
	.thumb { 
		max-height: 200px;
	}

	.cs_title {
		font-size: 25px;
	}

	.caseStudyWrap {
		overflow-x: scroll;
	}

	.caseStudyWrap::-webkit-scrollbar {
		display: none;
		width: 0;
		height: 0;
		background: transparent;
	}
	

	.cs_list {
		display: flex;
		flex-wrap: nowrap;
		gap: 10px;
	}

	.listItem {
		min-width: 135px;
	}

	.guide_meta_extra .chip,
	.guide_meta_readingTime {
		font-size: 12px;
	}

	.guide_meta_extra {
		display: none;
	}

	.guide_meta {
		grid-template-rows: 1fr;
	}

	.listItem > div {
		grid-template-rows: auto 1fr;
		gap: 10px;
		box-shadow: none;
		box-shadow: 0 2px 3px 1px var(--shadow);
	}
}