.team_quotes {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.team_quotes_text {
	text-align: right;
}

.team_quotes p {
	font-family: var(--secondary-font);
	font-size: 30px;
	font-style: normal;
	font-weight: 400;
	line-height: 49px;
	color: var(--middle-blue);
}

.co_founders {
	margin-left: -52px;
}

.co_founders img {
	max-width: 600px;

}

.team_member {
	display: flex;
	flex-direction: column;
	border: 2px solid var(--main-dark);
	border-radius: 30px;
	max-width: 430px;
	overflow: hidden;
	margin-bottom: 30px;
}

.team_member_meta {
	text-align: center;
	display: grid;
	padding: 30px;
	justify-items: center;
	color: var(--third-logo-color);
}

.team_member_meta .downArrow {
	transform: rotate(-90deg);
	transition: all 0.5s ease;
	cursor: pointer;
}

.team_member_meta .downArrow:hover {
	transform: rotate(-630deg);
} 

.team_member_meta .downArrow.activeArrow {
	transform: rotate(90deg);
}

.team_member_meta .downArrow.activeArrow:hover {
	transform: rotate(-450deg);
}

.team_member_meta h3,
.team_member_meta p {
	color: var(--third-logo-color);
	margin-bottom: 0;
	font-weight: 500;
}

.team_member_content {
	font-style: normal;
	font-weight: 400;
	line-height: 31px;
	padding: 0 30px;
	height: 0;
	transition: all 0.2s ease;
	opacity: 0;
}

.activeDetais {
	height: auto;
	overflow: visible;
	opacity: 1;
	padding: 0 30px 30px;
}


.team_members_container {
	column-count: 2;
	column-gap: 30px;
	max-width: 1000px;
	gap: 30px;
	justify-content: center;
	margin: 90px auto 0;
	align-items: start;

}

.team_member_photo{
	max-height: 426px;
	overflow: hidden;
}

.team_member_photo img {
	max-width: 100%;
	display: block;
	min-height: 426px;
	
}

@media screen and (max-width: 960px) {
	.team_members_container {
		flex-direction: column;
		padding: 0 15px;
		margin:  30px 0;
	}

	.team_members_container {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	.team_member_photo {
		height: 200px;
	}

	.team_member_photo img {
		min-height: 126px;
		margin: 0 auto;
		max-height: 100%;
		
	}

	.team_member_meta {
		padding: 10px 20px;
	}

	.team_member_meta h3 {
		font-size: 25px;
		line-height: 27px;
	}

	.team_member_meta p {
		font-size: 17px;
	}

	.team_member_content {
		padding: 0 15px 15px;
	}

	.team_member_content p {
		font-size: 18px;
		line-height: 25px;
	}

	.team_quotes {
		grid-template-columns: 1fr;
		
	}

	.team_quotes p {
		font-size: 22px;
		line-height: 39px;
	}

	.co_founders {
		margin: 20px 5%;
	}

	.co_founders img {
		max-width: 50%;
		margin-top: 0;
	}

}

@media screen and (max-width: 768px) {
	.team_members_container {
		grid-template-columns: 1fr;
	}
}