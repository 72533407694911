.atlaContainer {
	display: grid;
	gap: 15px;
}

.agreementForm {
	display: grid;
	gap: 10px;
	font-size: 18px;
}

.importantText {
	border-radius: 10px;
	background-color: var(--modal-gray);
	padding: 20px;
}

.agreementForm span {
	font-size: 18px;
	font-family: var(--primary-font) ;
	color: var(--main-dark)
}

.agreementForm p {
	font-size: 20px!important;
}

.atlaAIWrapper {
	background-color: var(--modal-gray);
	box-shadow: 0 0 0 100vmax var(--modal-gray);
	clip-path: inset(0 -100vmax);
	padding: 0;
	width: 100%;  
	max-width: 100%!important;
	position: relative;
	display: grid;
	height: calc(100dvh - 90px);
	margin-top: -90px;
	justify-content: center;
	grid-template-columns: 100%;
	padding-top: 40px;
	margin-bottom: -50px;
}


@media screen and (max-width: 940px) { 
	.atlaAIWrapper {
		height: calc(100dvh - 10px);
	}
	    
}

@media screen and (max-width: 740px) { 
	.atlaAIWrapper {
		margin-bottom: -51px;
		height: 100dvh;
	}
}