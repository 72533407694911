.notification_wrap {
	border-radius: 15px;
	width: 100%;
	background: var(--coral-second);
	padding: 39px 50px 39px 15px;
	margin-top: 38px;
	position: relative;
	display: grid;
	grid-template-columns: auto 1fr;
	gap: 24px;
}

.notification_wrap p{
	font-size: 22px;
	font-style: normal;
	font-weight: 400;
	line-height: 31px; /* 140.909% */
	letter-spacing: -0.22px;
}

.photo {
	border-radius: 50%;
	overflow: hidden;
	display: block;
	width: 87px;
	height: 87px;
}

.photo img {
	width: 100%;
}

.close {
	position: absolute;
	top: 15px;
	right: 15px;
	width: 24px;
	height: 24px;
	cursor: pointer;
	transition: all 0.4s ease;
}

.close:hover {
	transform: rotate(360deg);
}

.close  line,
.close  circle {
	stroke: var(--main-dark)
}


@media screen and (max-width: 935px) {
	.notification_wrap {
		margin-top: 0;
		gap: 0;
	}

	.notification_wrap p {
		font-size: 19px;
		line-height: 25px;
	}
}
@media screen and (max-width: 768px) {
	.notification_wrap {
		gap: 0;
		padding: 39px 30px 19px 15px;
		margin-top: 0;
	}

	.notification_wrap p{
		font-size: 18px;
		line-height: 21px;
	}
}