.coachItem, .coaching {
	display: grid;
	gap: 15px;
}

.coachImg {
	width: 200px;
	height: 200px;
	display: grid;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	border-radius: 50%;
}

.coachImg  img {
	width: 100%;
}