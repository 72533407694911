.loginWrapper {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	padding-top: 0;
	text-align: center;
	gap:20px;
}

.loginWrapper img {
	max-width: 100px;
}

.loginWrapper  button {
	min-width: 200px;
}

.form_group {
	display: grid;
	gap: 10px;
	justify-items: start;
	width: 100%;
	
}

.modalContainer  h3 {
	grid-column: 1/-1;
}

.form_oneline {
	display: flex;
	gap: 16px;
	width: 100%;
}

.loginWrapper input.error_field {
	border: 2px solid var(--error);
}

.loginWrapper input.error_field::placeholder {
	color: var(--error);
}

.errorText {
	margin: 3px 14px 0;
	text-align: left;
	letter-spacing: 0.0333em;
	color: var(--error);
	font-family: Roboto,Helvetica,Arial,sans-serif;
	font-size: 0.75rem;
	font-weight: 400;
	line-height: 1.66;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
	transition: background-color 600000s 0s, color 600000s 0s;
}

.buttonGroup {
	display: flex;
	gap: 20px;
	margin-top: 15px;
}

.login_error {
	margin-left: 22px;
	padding: 7px 23px;
	text-align: left;
	color: var(--error);
	border-radius: 5px;
	background: var(--first-logo-color);
	justify-self: left;
}

.label{
	position: relative;
}

.visibilityIcon {
	position: absolute;
	bottom: 10px;
	right: 7px;
	transition: all 0.4s ease;
}

.visibilityIcon:hover {
	cursor: pointer;
	color: var(--middle-blue);
}


@media screen and (max-width: 768px) {
	.form_group button {
		flex-grow: 1;
	}
	
}