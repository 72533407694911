.dashboardUserData {
	padding-left: 30px;
	margin-top: 100px;
	margin-bottom: 30px;
	display: grid;
	grid-template-columns: auto 1fr;
	gap: 10px 20px;
	color: var(--third-logo-color);
	font-weight: 400;
	font-size: 16px;
	line-height: 18px;
}

.dashboardUserData p {
	color: var(--third-logo-color);
	font-size: 16px;
	line-height: 18px;
}

.dashboardUserData strong {
	font-weight: 500;
	text-transform: capitalize;
}

.avatar {
	grid-row-start: 1;
	grid-row-end: 5;
}

.goToAccount {
	cursor: pointer;
	transition: all 0.4s ease;
}

.goToAccount:hover {
	opacity: 0.7;
}

.dashboardUserData p.logoutLink {
	color: var(--red);
	cursor: pointer;
	overflow: hidden;
	font-weight: 500;
	padding-bottom: 4px;
}

.logoutLink span {
	position: relative;
	overflow: hidden;
}

.logoutLink span::after {
	content: '';
	position: absolute;
	bottom: -1px;
	display: inline;
	border-bottom: 1px solid var(--red);
	width: 100%;
	left: 0;
	transition: all 0.4s ease;
}

.logoutLink span:hover::after{
	left:-100%;
}

.accActive {
	font-weight: 500;
}

.accActive span {
	color: var(--white)
}