.article {
	position: relative;
	min-height: 100svh;
}

.article h2 {
	font-weight: 400;
	font-size: 50px;
	line-height: 59px;
	letter-spacing: -0.02em;
}

.article h3 {
	display: flex;
	align-items: center;
}

.article .addToBook {
	font-size: 40px!important;
	margin-left: 15px;
}

.loader {
	position: absolute;
	top: 42%;
	left: 50%;
}

.componentNotFound {
	height: auto;
}