.wellbeing_form {
	background-color: #fff;
	padding: 15px 30px;
	border-radius: 15px;

}

.wellbeing_form form {
	margin-top: 30px;
	display: grid;
	gap: 30px;
}

.wellbeing_form form button {
	justify-self: center;
	display: flex;
	align-items: center;
}

.table_title {
	margin-top: 50px;
}

.headerClassName {
	background-color: var(--main-dark);
	color: var(--white)!important;
	font-weight: 500;
	font-family: var(--primary-font);
}



.externalLink {
	cursor: pointer;
	transition: opacity 0.4s ease;
	display: flex;
	align-items: center;
	gap: 10px;
}

.externalLink:hover {
	opacity: 0.3;
}

.headerClassName  svg path {
	stroke: var(--white)
}

.tableWrap {
	background-color: #FFF;
}

.deleteConfirmDialog .deleteConfirmBtn{
	padding: 0 40px 40px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 30px;
}

.deleteConfirmDialog .deleteConfirmText {
	font-size: 30px;
	font-weight: 500;
	padding-top: 40px;
}
