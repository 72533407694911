.stackItem {
	padding: 18px 25px;
	border: 2px solid var(--main-dark);
	display: grid;
	grid-template-columns: 1fr auto;
	background-color: var(--modal-gray)!important;
	justify-items: start;
	box-shadow: none;
	border-radius: 10px;
	cursor: pointer;
	transition: all 0.4s ease;
	align-items: center;
}

.stackItem p {
	margin-bottom: 0;
}

.stackItem .mailChimp {
	border-color: var(--main-dark);
	text-transform: capitalize;
}

.stackItem:hover {
	background-color: var(--light-blue);
	cursor: pointer;
}

.stack_meta {
	display: flex;
	gap: 10px;
	align-items: center;
}

.guide_meta_time {
	font-size: 13px;
	display: inline-flex;
	gap: 4px;
	align-items: center;
	margin-bottom: 0!important;
}

@media screen and  (max-width: 768px){
	.stackItem {
		grid-template-columns: 1fr;
		gap: 20px;
		padding: 10px;
	}
}