.dashboardWrap {
	display: grid;
	min-height: 100svh;
	grid-template-rows:auto 1fr auto;
	background-color: var(--dashboard-bg);
}

.adminDashboardWrap {
	display: grid;
	grid-template-columns: 310px 1fr;
	min-height: 100svh;
	grid-template-rows: 1fr auto;
}

.topMobileNav {
	display: grid;
	grid-template-columns: auto 247px;
	gap: 20px;
	align-items: center;
}

.becomeMember {
	position: absolute;
	right: 80px;
}

.checkbox,
.hamburger_lines {
	display: none;
}

.adminDashboardWrap footer {
	grid-column: 1/-1;
}

.dashboardContent {
	padding: 35px 30px;
	position: relative;
	margin: 0 auto;
	width: 100%;
	max-width: calc(var(--max-container-width) - 11%);
}

.dashboardWrap .dashboardContent {
	display: grid;
	grid-template-columns: 1fr;
	gap: 30px;
	padding-top: 130px;
}

.dashboardBar {
	display: grid;
	grid-template-columns: 1fr  auto ;
	gap: 16px;
	align-items: center;
	max-width: var(--max-container-width);
	width: 100%;
	padding: 0 20px;
	margin: 18px auto 20px;
}

.adminDashboardWrap  .dashboardBar {
	display: none;
}

.topbar {
	transition: all 0.5s ease;
	transform: translateZ(0);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	max-width: 100%;
	background-color: var(--dashboard-bg);
	z-index: 99;
}

.sticky {
	transition: all 0.5s ease;
	transform: translateZ(0);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	max-width: 100%;
	background-color: var(--dashboard-bg);
	z-index: 90;

}

.sticky .dashboardBar {
	margin: 18px auto 20px;
}

.dashboardBar .logo {
	justify-self: self-start;
}

.leaveSiteBtn {
	position: fixed;
	top: 55%;
	right: -62px;
	padding: 12px 8px;
	transform: rotate(90deg);
	width: 170px;
	z-index: 99;
}

.adminDashboardWrap .dashboardContent {
	overflow: hidden;
	max-width: 100%;
}

@media screen and (max-width: 1460px) {
	.dashboardContent {
		padding: 35px 30px;
	}

}
@media screen and (max-width: 935px) {
	.topMobileNav {
		grid-template-columns: auto 147px;
	}

	.dashboardWrap .dashboardContent {
		padding-top: 100px;
	}
}

@media screen and (max-width: 768px) {
	.dashboardWrap .dashboardContent  {
		padding: 90px 20px 30px;
		gap: 20px;
	}

	.logo_link {
		position: relative;
		z-index: 3;
	}

	.logo_link_light span {
		color: var(--white)
	}

	.becomeMember {
		right: 10px;
		top: -15px;
	}

	.becomeMember button {
		width: 140px;
		font-size: 12px;
		padding: 5px 10px;
	}

	.checkbox {
		position: absolute;
		z-index: 5;
		top: 12px;
		right: 0;
		display: block;
		width: 23px;
		height: 18px;
		cursor: pointer;
		opacity: 0;
	}

	.hamburger_lines {
		position: relative;
		z-index: 2;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 24px;
		height: 18px;
	}

	.line {
		display: block;
		width: 100%;
		height: 2px;
		border-radius: 3px;
		background: #0E2431;
	}

	.line1 {
		transition: transform 0.4s ease-in-out;
		transform-origin: 0% 0%;
	}

	.line2 {
		transition: transform 0.2s ease-in-out;
	}

	.line3 {
		transition: transform 0.4s ease-in-out;
		transform-origin: 0% 100%;
	}

	input[type="checkbox"]:checked ~ .menu {
		transform: translateX(0);
	}



	input[type="checkbox"]:checked ~ .hamburger_lines .line1 {
		transform: rotate(45deg);
	}

	input[type="checkbox"]:checked ~ .hamburger_lines .line2 {
		transform: scaleY(0);
	}

	input[type="checkbox"]:checked ~ .hamburger_lines .line3 {
		transform: rotate(-45deg);
	}

	.dashboardBar {
		display: grid;
		grid-template-columns: 1fr auto;
		justify-content: flex-end;
		align-items: center;
		transition: all 0.5s ease;
		transform: translateZ(0);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		background-color: var(--dashboard-bg);
		margin: 0;
		padding: 18px 20px;
	}

	.sticky .dashboardBar {
		margin: 0;
	}

	.topMobileNav {
		display: flex;
		align-items: center;
		position: relative;
		gap: 15px;
		z-index: 4;
	}

	.dashboardBar.sticky {
		position: fixed;
		z-index: 999;
		top: 0;
		left: 0;
		width: 100%;
		transform: rotate(360deg);
		animation:slide-down 0.7s;
		background: var(--white); 
	}

	@keyframes slide-down {
		0% {
			transform: translateY(-100%);
			opacity: 0;
		} 

		100% {
			transform: translateY(0);
			opacity: 0.9;
		} 
	}
	


	.dashboardBar img {
		height: 17px;
	}

	.dashboardBar  button {
		margin: 0;
	}

	.dashboardWrap .isOpened {
		left: 0;
	}
}
@media screen and (max-width: 768px){
	.dashboardWrap {
		grid-template-columns: 1fr;
	}
}


@media screen and (max-width: 1024px) {
	.adminDashboardWrap {
		grid-template-columns: 1fr;
	}
}