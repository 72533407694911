.postsList {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 30px;
}

.postWrap {
	max-width: 960px!important;
	margin: 50px auto!important;
}

.reading_time {
	text-align: center;
	font-size: 19px;
	font-style: normal;
	font-weight: 400;
	line-height: 31px;
	font-family: var(--secondary-font);
}

.postTitle {
	text-align: center;
	font-size: 50px;
	margin: 13px 0 45px;
	line-height: 60px;
}

.postTitle::first-letter {
	text-transform: uppercase;
}

.postMainImage {
	width: 100%;
	margin-bottom: 40px;
	max-height: 400px;
	overflow: hidden;
}

.postMainImage img {
	max-width: 100%;
}

.hashtags {
	margin-top: 10px;
	display: flex;
	gap: 10px;
	align-items: center;
	margin-bottom: 20px;
	flex-wrap: wrap;
}

.postItem {
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	min-height: 430px;
	border-radius: 10px;
	overflow: hidden;
}

.postImg {
	aspect-ratio: 3 / 2;
	background-color:  #91CCD1;
	background-position: 50% 50%;
	background-size: cover ;
	background-repeat: no-repeat;
}

.postData  {
	background: var(--lime);
	padding: 30px 25px;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	-webkit-box-flex: 1;
	flex: 1;
	margin-bottom: auto;
	gap: 20px;
	justify-content: space-between;
}

.categories {
	display: flex;
	gap: 10px;
	flex-wrap: wrap;
}

.categories  span {
	font-family: var(--primary-font);
}

.post_title::first-letter {
	text-transform: uppercase;
}

.loadMoreBtn {
	margin: 120px auto -30px;
	display: block;
}

.filter {
	margin-bottom: 30px;
}

.filter .filterLabel {
	padding: 0!important;
	margin-top: -15px;
	margin-left: -20px;
}

.filterChips {
	gap: 10px;
	display: flex;
}

.filterChips  span {
	font-family: var(--primary-font);
}

.recentPosts {
	border-top: 2px solid var(--main-dark)
}

.recentPosts h2 {
	font-size: 50px;
	margin-bottom: 50px;
}

@media screen and (max-width: 1200px) {
	.postItem {
		min-height: auto;
	}
}

@media screen and (max-width: 1050px) {
	.postsList {
		grid-template-columns: 1fr  1fr;

	}
}


@media screen and (max-width: 768px) {
	.postsList {
		grid-template-columns: 1fr ;
	}
}