.container {
	display: grid;
	grid-template-columns:  1fr 1fr 1fr 1fr;
	gap: 30px;
	padding: 30px;
}

.container div {
	min-width: 25%;
	display: flex;
	align-items: center;
	gap: 15px;
}

.container div svg {
	max-width: 90px;
	max-height: 60px;
}