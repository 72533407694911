.termsForm a {
	font-size: 18px;
	font-weight: 400;
	display: ruby;
}

.termsForm p {
	margin-bottom: 20px;
}

.checkboxes {
	display: grid;
	margin-top: 30px;
	gap: 10px;
	
}

.checkboxes span {
	font-size: 18px;
	line-height: 31px;
	font-family: var(--primary-font);
}

.checkboxes button {
	margin-top: 30px;
}

.checkboxes label {
	display: flex;
	gap: 5px;
	align-items: center;
}