.addToBook {
	font-weight: 500;
	font-size: 24px;
	line-height: 25px;
	justify-self: end;
	display: flex;
	align-items: center;
	gap: 4px;
	cursor: pointer;
	transition: all 0.4s ease;
	color: var(--light-grey);
}

.addToBook svg {
	height: 25px;
	margin-right: 7px;
}

.addToBook:hover {
	cursor: pointer;
	color: var(--main-dark);
}

.addToBook:hover line,
.addToBook:hover path {
	fill: var(--main-dark);
	transition: all 0.4s ease;
}

.addToBook line,
.addToBook path {
	fill: var(--light-grey);
}

.booked{
	color: var(--main-dark);
}

.booked line,
.booked path {
	fill: var(--main-dark);
}

@media screen and (max-width: 935px) {
	.addToBook {
		font-size: 22px;
	}
}
@media screen and (max-width: 768px) {
	.addToBook {
		font-size: 16px;
	}
}