
.modalWrap {
	background-color: var(    --dashboard-bg); 
	display: grid;
	grid-template-columns: 1fr auto;
	height: 100svh;
	max-width: 100vw;
	width: 100%;
}

.modalTitle {
	background-color: var(--main-dark);
	display: grid;
	grid-template-columns: 1fr auto;
}

.dark .modalWrap {
	background-color: var(--main-dark); 
}

.light .modalTitle{
	background-color: var(    --dashboard-bg); 
}

.text_under_box  a {
	color: var(--light-grey);
	font-size: 14px;
	font-weight: 400;
	line-height: 23px;
}

.modalWrap .styledBox {
	box-shadow: none;
	max-width: 100%;
	width: 100%;
	grid-column: 1 / -1;
	padding: 32px 0;
	position: relative;
	display: grid;
	grid-template-rows:  1fr auto auto;
	height: 100%;
	justify-content: left;
	grid-template-columns: 1fr;
}

.modalWrap .styledBox > div {
	width: calc(100% - 20px);
	max-width: 870px;
}

.dark p,
.dark li {
	color: var(--white);
}

.light svg circle,
.light svg line{
	stroke: var(--main-dark);
}

.dark li svg circle,
.dark li svg path{
	stroke: var(--white);
}

.modalWrap:focus-visible {
	outline: none;
}

.close {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-bottom: 10px;
	margin-left: 3px;
	cursor: pointer;
	color: var(--white);
	gap: 10px;
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
	transition: all 0.5s ease;
	z-index: 9;
}

.light .close {
	color: var(--main-dark);
}



.logo {
	z-index: 9;
	
}

.dark .logo {
	color: var(--white);
}

.light .logo {
	color: var(--main-dark);
}

.close:hover {
	color: var(--middle-blue);
}

.close svg{
	transition: all 0.4s ease;
}

.close:hover svg{
	transform: rotate(360deg);
}

.close:hover svg circle,
.close:hover svg line{
	stroke: var(--middle-blue);
}
@media screen and (max-width: 768px) {
	.modalWrap {
		padding: 15px;
	}

	.logo {
		font-size: 22px;
		margin-bottom: 0;
		display: flex;
		align-items: center;
	}

	.close {
		align-items: center;
		margin-bottom: 0;
	}

	.close svg {
		width: 20px;
		height: 20px;
	}
	
	.modalWrap .styledBox > div {
		width: 100%;
	}
}