.pathwayList {
	display: grid;
	gap: 10px;
	margin-top: 32px;
}

.pathwayList_item {
	display: grid;
	grid-template-columns: auto 1fr;
	gap: 24px;
	position: relative;
}

.pathwayList_item:last-child {
	margin-bottom: 40px;
}

.finishedCircle {
	background-color: var(--main-dark)!important;
}

.pathwayList_item::before {
	content: '';
	width: 9px;
	height: 100%;
	position: absolute;
	background-color: var(--third-logo-color);
	left: 16px;
	z-index: 0;
	top: 35px;
}

.finishedItem::before {
	background-color: var(--main-dark);
}

.pathwayList_item h3 {
	margin: 0;
}

.pathwayList_item a{
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 32px;
}

.pathwayList_item_step {
	color: var(--white);
	z-index: 1;
}

.nestedList {
	grid-column-start: 2;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 25px;
	margin: 0 0 24px;
	display: grid;
	gap: 12px 25px;
}

.pathwayList_item:last-child .nestedList {
	margin-bottom: 0;
}

@media screen and (max-width: 768px) {
	.pathwayList_item a {
		font-size: 25px;
		line-height: 35px;
	}

	.pathwayList_item {
		gap: 10px;
	}

	.nestedList {
		gap: 19px;
		font-size: 18px;
		line-height: 21px;
	}

	.pathwayList_item::before { 
		left: 12px;
		top: 20px;
	}
}