
.uploadFileBtn {
	background-color: var(--text-highlight);
	border: 1px solid #DDD;
	padding: 10px 20px;
	display: inline-block;
	border-radius: 5px;
	cursor: pointer;
	font-weight: 500;
	color: var(--white);
	transition: all 0.5s ease;
	margin-right: 10px;
}

.uploadFileBtn:hover {
	background-color: var(--middle-blue);
} 

.attachList p{
	font-size: 19px;
	text-transform: lowercase;
	display: flex;
	gap: 10px;
	align-items: center;
}

.attachList p svg {
	width: 18px;
	height: 18px;
	cursor: pointer;
}

.attachList {
	margin-bottom: 15px;
}

.attachImage {
	margin-bottom: 20px;
	aspect-ratio: 3/2;
	overflow: hidden;
	max-width: 30%;
	margin-top: 20px;
}

.attachImage svg {
	position: absolute;
}

.attachImage img {
	max-width: 100%;

}